/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
  position: sticky;
  top: 0;
  z-index: $layer-header;
  padding: 1.5rem var(--container-gutter);
  background-color: rgba($color-white, 0.975);
  display: flex;
  align-items: center;

  @include bp(md) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  a:not(.c-button):hover,
  button:hover {
    color: $color-link;
  }

  .has-menu-open & {
    color: $color-lt-blue;
    background-color: transparent;
    transition: color 0s 0.5s, background-color $global-transition-speed 0.5s;
  }
}

.c-header__hamburger {
  flex-shrink: 0;
}

.c-header__logo {
  @include bp(md) {
    &:not(:last-child) {
      margin-right: 2em;
    }
  }

  &--custom {
    transition: opacity $global-transition-speed;

    .has-menu-open & {
      opacity: 0;
      pointer-events: none;
      transition-delay: 0.5s;
    }
  }
}

.c-header__nav {
  --button-font-size: 1.8rem;

  margin-left: auto;
  transition: opacity $global-transition-speed;

  .has-menu-open & {
    opacity: 0;
    pointer-events: none;
    transition-delay: 0.5s;
  }
}
