/* ==========================================================================
   #BODY
   ========================================================================== */

.o-body {
  @include dimmer(
    ('has-popup-open', 'is-loading'),
    $layer-popup - 1,
    rgba($color-purple, 0.9)
  );

  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
  background: $color-bg;
  color: $color-text;
  font-size: $font-size;
  font-family: $font-family;
  font-weight: $font-weight;
  line-height: $font-line-height;

  &.is-loading::before {
    content: '';
    position: fixed;
    inset: 0;
    z-index: $layer-popup;
    background-image: url('../assets/images/loader.svg');
    background-position: center;
    background-repeat: no-repeat;
  }
}
