/* ==========================================================================
   #LINK
   ========================================================================== */

.c-link {
  display: inline-flex;
  align-items: center;
  color: currentColor;
  font-size: 1.8rem;
  letter-spacing: 0.08em;
  text-transform: uppercase;

  &:hover {
    opacity: 0.8;
  }
}

.c-link__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1em;
  margin-top: -0.1em;
  padding: 0.5em;
  background-color: var(--section-color);
  border-radius: 50%;
  box-shadow: 0 0.5rem 1rem rgba($color-black, 0.15);
  color: var(--section-bg-color);

  svg {
    display: block;
    height: 1em;
    width: 1em;

    * {
      fill: currentColor;
      stroke: none;
    }
  }
}
