/* ==========================================================================
   #PROGRAM
   ========================================================================== */

.c-program {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.c-program__image {
  width: 100%;
}

.c-program__content {
  @include spacer(2);
}

.c-program__link {
  &:hover {
    opacity: 0.8;
  }

  > span {
    display: inline;
    white-space: nowrap;
  }
}

.c-program__button {
  @extend %plus-sign;

  margin-left: $global-spacer;
  color: $color-shade;
  vertical-align: 0.2em;
}

.c-program__copy {
  @include spacer(1.5);

  max-width: 42rem;
  font-size: 2rem;
  line-height: 1.5;
}
