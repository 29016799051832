/* ==========================================================================
   #CONTAINER
   ========================================================================== */

%container {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: calc(var(--container-gutter) * 2 + #{$container-width});
  padding-left: var(--container-gutter);
  padding-right: var(--container-gutter);
}
