/* ==========================================================================
   #PLUS SIGN
   ========================================================================== */

%plus-sign {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: var(--plus-width, 2.2rem);

  &::before,
  &::after {
    content: '';
    display: block;
    width: var(--plus-width, 2.2rem);
    height: var(--plus-height, 0.5rem);
    background: currentColor;
    transition: transform $global-transition-speed;
  }

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0) rotate(90deg);

    .is-expanded & {
      transform: translate3d(-50%, -50%, 0);
    }
  }
}
