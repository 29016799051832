/* ==========================================================================
   #Menu
   ========================================================================== */

.c-menu {
  position: fixed;
  inset: 0;
  top: -100%;
  z-index: $layer-menu;
  padding: var(--container-gutter);
  padding-top: var(--header-outer-height);
  color: $color-white;
  overflow: hidden;
  pointer-events: none;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);

  @include bp(md) {
    padding-left: calc(var(--container-gutter) + (2 * var(--hamburger-width)));
  }

  &::before {
    @include position-center;

    content: '';
    width: 100%;
    height: 0;
    background-color: $color-navy;
    padding-bottom: 100%;
    border-radius: 100%;
    overflow: hidden;
    opacity: 0;
    transform: scale(0.04) translateY(9999px);
    transition: opacity $global-transition-speed;
  }

  .has-menu-open & {
    top: 0;
    pointer-events: all;

    &::before {
      opacity: 1;
      animation: menu-animation 0.8s ease-out forwards;
    }
  }
}

.c-menu__inner {
  --menu-offset: 2.5vh;

  @include bp(md) {
    --menu-offset: 5vh;
  }

  @include bp(lg) {
    --menu-offset: 7.5vh;
  }

  position: relative;
  z-index: $layer-base;
  margin-top: var(--menu-offset);
  height: calc(100% - var(--menu-offset));
  padding-left: 2rem;
  margin-left: -2rem;
  overflow-y: auto;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
}

.c-menu__nav {
  > ul > li {
    opacity: 0;
    transform: translate3d(-0.75em, 0, 0);

    .has-menu-open & {
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition-property: transform, opacity;
      transition-duration: $global-transition-speed;
      transition-timing-function: ease-out;

      @for $i from 1 to 10 {
        &:nth-child(#{$i}) {
          transition-delay: $i * 0.1s + 0.65s;
        }
      }
    }
  }
}

.c-menu__socials {
  opacity: 0;
  transform: translate3d(-0.75em, 0, 0);

  .has-menu-open & {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition-property: transform, opacity;
    transition-duration: $global-transition-speed;
    transition-delay: 1s;
    transition-timing-function: ease-out;
  }

  &:not(:first-child) {
    margin-top: 4rem;

    @include bp(md) {
      margin-top: 8rem;
    }
  }
}

.c-menu__title {
  color: $color-lt-blue;

  &:not(:last-child) {
    margin-bottom: 1em;
  }
}
