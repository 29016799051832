/* ==========================================================================
   #COPY
   ========================================================================== */

%copy {
  @extend %style-copy;

  @for $i from 1 through 6 {
    h#{$i}:not(.c-heading) {
      @include spacer-relative(1.5);

      @extend %title !optional;
      @extend %title-h#{$i} !optional;

      + p,
      + ul,
      + ol,
      + table,
      + address {
        @include spacer-relative(0.75);
      }
    }
  }

  h1:not(.c-heading) {
    font-weight: normal;
  }

  ul,
  ol {
    padding-left: 1.5em;

    li {
      padding-left: 0.5em;
    }
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style-type: decimal;
  }

  li {
    @include spacer-relative(0.75);
  }

  img {
    max-width: 100%;
    height: auto;
  }

  iframe {
    display: block;
    width: 100%;
  }

  em {
    font-style: italic;
  }

  strong {
    font-weight: 700;
  }

  mark {
    padding: 0.25em;
    background-color: rgba($color-lt-blue, 0.35);
    font-style: italic;
  }

  p,
  ul,
  ol,
  table,
  address {
    @include spacer-relative(1.25);
  }

  a:not([class]) {
    @extend %link;

    text-decoration: underline;
  }

  [class*='wp-block'] figcaption {
    @include spacer;

    @extend %caption;
  }
}
