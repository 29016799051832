/* ==========================================================================
   #NAV OVERLAY
   ========================================================================== */

.c-nav-overlay {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

.c-nav-overlay__item {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 2.7rem;

  @include bp(md) {
    font-size: 3.2rem;
  }

  @include bp(lg) {
    font-size: 3.5rem;
  }

  &:not(:first-child) {
    margin-top: 1em;
  }
}

.c-nav-overlay__link {
  --button-bg-color: #{$color-lt-green};
  --button-border-color: #{$color-lt-green};
  --button-color: #{$color-navy};
  --button-padding: 0.25em 0.75em;
  --button-font-weight: normal;
  --button-min-width: 0;

  font-size: 1em;

  &:hover {
    --button-bg-color: #{$color-lt-green};
    --button-border-color: #{$color-lt-green};
    --button-color: #{$color-navy};

    opacity: 0.75;
  }

  &.c-button {
    margin-left: -1.5rem;

    @include bp(lg) {
      margin-left: -2rem;
    }
  }
}

.c-nav-overlay__toggle {
  position: relative;
  height: 2.4rem;
  width: 2.4rem;
  margin-left: 0.75em;
  color: $color-lt-tan;
  font-size: 2.4rem;
  transform: rotate(-90deg);
  transition: transform $global-transition-speed;

  .is-expanded & {
    transform: rotate(-90deg) scale(-1);
  }

  &:hover {
    opacity: 0.75;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 50%;
    width: 10%;
    height: 50%;
    background-color: currentColor;
  }

  &::before {
    top: 0;
    transform: skew(40deg, 0deg);
  }

  &::after {
    top: 50%;
    transform: skew(-40deg, 0deg);
  }
}

.c-nav-overlay__subnav {
  display: none;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 0.66em;

  .is-expanded & {
    display: flex;
  }
}

.c-nav-overlay__subnav-item {
  &:not(:first-child) {
    margin-top: 1.25em;
  }
}

.c-nav-overlay__subnav-link {
  color: $color-lt-tan;

  &[href]:hover,
  &[href]:focus {
    color: currentColor;
  }
}
