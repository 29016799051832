/* ==========================================================================
   #LEAD
   ========================================================================== */

.c-lead {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.c-lead__label {
  padding: 0 0.25em;

  &::after {
    content: '';
    display: block;
    width: 8rem;
    height: 1px;
    margin-left: auto;
    margin-right: auto;
    background-color: $color-shade;
  }

  a:hover {
    color: $color-purple;
  }
}

.c-lead__image {
  width: 17rem;

  @include bp(md) {
    width: 11rem;
  }
}

.c-lead__title {
  max-width: 92rem;

  &:not(:first-child) {
    margin-top: 2rem;
  }
}

.c-lead__copy {
  max-width: 82rem;

  &--spacing-small {
    &:not(:first-child) {
      margin-top: 2rem;
    }
  }

  &--spacing-medium {
    &:not(:first-child) {
      margin-top: 3rem;
    }
  }

  &--spacing-large {
    &:not(:first-child) {
      margin-top: 4rem;

      @include bp(lg) {
        margin-top: 5rem;
      }
    }
  }

  &--narrow {
    max-width: 55rem;
  }

  em {
    display: none;

    @include bp(md) {
      display: inline;
      font-style: normal;
    }
  }

  p {
    @include spacer;

    margin: 0;
  }
}

.c-lead__link {
  &:not(:first-child) {
    margin-top: 3rem;
  }

  &--iconized {
    &:not(:first-child) {
      @include bp(lg) {
        margin-top: 4.5rem;
      }
    }
  }
}

.c-lead__action {
  &:not(:first-child) {
    margin-top: 4rem;

    @include bp(lg) {
      margin-top: 6rem;
    }
  }
}

.c-lead__extras {
  display: contents;
  justify-content: inherit;

  &:not(:first-child) > * {
    margin-top: 3rem;
  }
}

/* Alignment variants
   ========================================================================== */

.c-lead--align-left {
  align-items: flex-start;
  margin-right: auto;
  text-align: left;
}

.c-lead--align-center {
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.c-lead--align-right {
  align-items: flex-end;
  margin-left: auto;
  text-align: right;
}

.c-lead--align-center-until-xl {
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  @include bp(xl) {
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 0;
    text-align: left;
  }
}
