/* ==========================================================================
   #LOGO
   ========================================================================== */

.c-logo {
  display: block;

  &[href]:hover {
    opacity: 0.75;
  }
}

/* Type variants
    ========================================================================== */

.c-logo--text {
  font-weight: 500;
  white-space: nowrap;
}

.c-logo--image {
  svg,
  img {
    display: block;
    width: 100%;
    height: 1.75em;

    @include bp(md) {
      height: 2em;
    }

    @include bp(lg) {
      height: 2.25em;
    }
  }
}

/* Size variants
    ========================================================================== */

.c-logo--sm {
  font-size: 0.75em;
}

.c-logo--md {
  font-size: 1em;
}

.c-logo--lg {
  font-size: 1.25em;
}
