/* ==========================================================================
   #TESTIMONIAL
   ========================================================================== */

.c-testimonial {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.c-testimonial__copy {
  max-width: 90rem;
  quotes: '“' '”' '‘' '’';

  p:first-child {
    &::before {
      content: open-quote;
    }
  }

  p:last-child {
    &::after {
      content: close-quote;
    }
  }
}

.c-testimonial__author {
  font-size: 2.2rem;
  font-weight: bold;

  &::before {
    content: '\2013';
  }

  &:not(:first-child) {
    margin-top: 1em;
  }
}

.c-testimonial__location {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $color-purple;
  font-size: 1.8rem;

  &:not(:first-child) {
    margin-top: 2em;
  }

  .c-icon {
    margin-bottom: 0.5em;
  }
}
