@charset "UTF-8";
/*
  Project: Common Counsel Foundation
  Author: piotr@kulpinski.pl
 */
/*!
** Name:      Bespoke Toolkit
** Version:   v1.0.25
** Author:    Piotr Kulpinski
** Copyright: 2020
** License:   MIT
** --------------------------------
**/
.tippy-box[data-animation=shift-toward][data-state=hidden] {
  opacity: 0;
}

.tippy-box[data-animation=shift-toward][data-state=hidden][data-placement^=top] {
  transform: translateY(-10px);
}

.tippy-box[data-animation=shift-toward][data-state=hidden][data-placement^=bottom] {
  transform: translateY(10px);
}

.tippy-box[data-animation=shift-toward][data-state=hidden][data-placement^=left] {
  transform: translateX(-10px);
}

.tippy-box[data-animation=shift-toward][data-state=hidden][data-placement^=right] {
  transform: translateX(10px);
}

@keyframes menu-animation {
  0% {
    opacity: 0;
    transform: scale(0.04) translateY(300%);
  }
  40% {
    transform: scale(0.04) translateY(0);
    transition: ease-out;
  }
  40% {
    transform: scale(0.04) translateY(0);
  }
  60% {
    opacity: 1;
    transform: scale(0.02) translateY(0);
  }
  61% {
    transform: scale(0.04);
  }
  99.9% {
    height: 0;
    padding-bottom: 100%;
    border-radius: 100%;
  }
  100% {
    transform: scale(2);
    height: 100%;
    padding-bottom: 0;
    border-radius: 0;
  }
}
/* Fonts
   ========================================================================== */
/* Breakpoints
   ========================================================================== */
/* Responsive display values
   ========================================================================== */
/* Layers
   ========================================================================== */
/* Colors
   ========================================================================== */
/* Typography
   ========================================================================== */
/* Layout
   ========================================================================== */
/* GLOBAL CSS VARIABLES
   ========================================================================== */
:root {
  --column-gutter: 2.4rem;
  --container-gutter: 3.2rem;
  --content-width: 95rem;
  --header-padding: 1.5rem;
  --header-height: 4.5rem;
  --header-outer-height: calc(
    var(--header-height) + (2 * var(--header-padding))
  );
  --hamburger-width: 3.5rem;
  --hamburger-height: 1rem;
  --hamburger-weight: 2px;
  --hamburger-color: currentColor;
  --hamburger-duration: 0.25s;
  --hamburger-radius: 3px;
}
@media (min-width: 768px) {
  :root {
    --header-padding: 2rem;
  }
}
@media (min-width: 1024px) {
  :root {
    --container-gutter: 5rem;
  }
}

/* ==========================================================================
   #CAPTION
   ========================================================================== */
.c-copy [class*=wp-block] figcaption {
  color: #3f5e4d;
  text-align: left;
}

/* ==========================================================================
   #CONTAINER
   ========================================================================== */
.o-container {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: calc(var(--container-gutter) * 2 + 124rem);
  padding-left: var(--container-gutter);
  padding-right: var(--container-gutter);
}

/* ==========================================================================
   #COPY
   ========================================================================== */
.c-copy h1:not(.c-heading):not(:first-child) {
  margin-top: calc(1.25em * 1.5);
  --spacer-margin-top: calc(1.25em * 1.5);
}
.c-copy h1:not(.c-heading) + p:not(:first-child),
.c-copy h1:not(.c-heading) + ul:not(:first-child),
.c-copy h1:not(.c-heading) + ol:not(:first-child),
.c-copy h1:not(.c-heading) + table:not(:first-child),
.c-copy h1:not(.c-heading) + address:not(:first-child) {
  margin-top: calc(1.25em * 0.75);
  --spacer-margin-top: calc(1.25em * 0.75);
}
.c-copy h2:not(.c-heading):not(:first-child) {
  margin-top: calc(1.25em * 1.5);
  --spacer-margin-top: calc(1.25em * 1.5);
}
.c-copy h2:not(.c-heading) + p:not(:first-child),
.c-copy h2:not(.c-heading) + ul:not(:first-child),
.c-copy h2:not(.c-heading) + ol:not(:first-child),
.c-copy h2:not(.c-heading) + table:not(:first-child),
.c-copy h2:not(.c-heading) + address:not(:first-child) {
  margin-top: calc(1.25em * 0.75);
  --spacer-margin-top: calc(1.25em * 0.75);
}
.c-copy h3:not(.c-heading):not(:first-child) {
  margin-top: calc(1.25em * 1.5);
  --spacer-margin-top: calc(1.25em * 1.5);
}
.c-copy h3:not(.c-heading) + p:not(:first-child),
.c-copy h3:not(.c-heading) + ul:not(:first-child),
.c-copy h3:not(.c-heading) + ol:not(:first-child),
.c-copy h3:not(.c-heading) + table:not(:first-child),
.c-copy h3:not(.c-heading) + address:not(:first-child) {
  margin-top: calc(1.25em * 0.75);
  --spacer-margin-top: calc(1.25em * 0.75);
}
.c-copy h4:not(.c-heading):not(:first-child) {
  margin-top: calc(1.25em * 1.5);
  --spacer-margin-top: calc(1.25em * 1.5);
}
.c-copy h4:not(.c-heading) + p:not(:first-child),
.c-copy h4:not(.c-heading) + ul:not(:first-child),
.c-copy h4:not(.c-heading) + ol:not(:first-child),
.c-copy h4:not(.c-heading) + table:not(:first-child),
.c-copy h4:not(.c-heading) + address:not(:first-child) {
  margin-top: calc(1.25em * 0.75);
  --spacer-margin-top: calc(1.25em * 0.75);
}
.c-copy h5:not(.c-heading):not(:first-child) {
  margin-top: calc(1.25em * 1.5);
  --spacer-margin-top: calc(1.25em * 1.5);
}
.c-copy h5:not(.c-heading) + p:not(:first-child),
.c-copy h5:not(.c-heading) + ul:not(:first-child),
.c-copy h5:not(.c-heading) + ol:not(:first-child),
.c-copy h5:not(.c-heading) + table:not(:first-child),
.c-copy h5:not(.c-heading) + address:not(:first-child) {
  margin-top: calc(1.25em * 0.75);
  --spacer-margin-top: calc(1.25em * 0.75);
}
.c-copy h6:not(.c-heading):not(:first-child) {
  margin-top: calc(1.25em * 1.5);
  --spacer-margin-top: calc(1.25em * 1.5);
}
.c-copy h6:not(.c-heading) + p:not(:first-child),
.c-copy h6:not(.c-heading) + ul:not(:first-child),
.c-copy h6:not(.c-heading) + ol:not(:first-child),
.c-copy h6:not(.c-heading) + table:not(:first-child),
.c-copy h6:not(.c-heading) + address:not(:first-child) {
  margin-top: calc(1.25em * 0.75);
  --spacer-margin-top: calc(1.25em * 0.75);
}
.c-copy h1:not(.c-heading) {
  font-weight: normal;
}
.c-copy ul,
.c-copy ol {
  padding-left: 1.5em;
}
.c-copy ul li,
.c-copy ol li {
  padding-left: 0.5em;
}
.c-copy ul {
  list-style: disc;
}
.c-copy ol {
  list-style-type: decimal;
}
.c-copy li:not(:first-child) {
  margin-top: calc(1.25em * 0.75);
  --spacer-margin-top: calc(1.25em * 0.75);
}
.c-copy img {
  max-width: 100%;
  height: auto;
}
.c-copy iframe {
  display: block;
  width: 100%;
}
.c-copy em {
  font-style: italic;
}
.c-copy strong {
  font-weight: 700;
}
.c-copy mark {
  padding: 0.25em;
  background-color: rgba(183, 187, 224, 0.35);
  font-style: italic;
}
.c-copy p:not(:first-child),
.c-copy ul:not(:first-child),
.c-copy ol:not(:first-child),
.c-copy table:not(:first-child),
.c-copy address:not(:first-child) {
  margin-top: calc(1.25em * 1.25);
  --spacer-margin-top: calc(1.25em * 1.25);
}
.c-copy a:not([class]) {
  text-decoration: underline;
}
@media (min-width: 0) {
  .c-copy [class*=wp-block] figcaption:not(:first-child) {
    margin-top: calc(
          2rem * 0.25
        );
    --spacer-margin-top: calc(
      2rem * 0.25
    );
  }
}
@media (min-width: 768px) {
  .c-copy [class*=wp-block] figcaption:not(:first-child) {
    margin-top: calc(
          2rem * 0.5
        );
    --spacer-margin-top: calc(
      2rem * 0.5
    );
  }
}
@media (min-width: 1024px) {
  .c-copy [class*=wp-block] figcaption:not(:first-child) {
    margin-top: calc(
          2rem * 0.75
        );
    --spacer-margin-top: calc(
      2rem * 0.75
    );
  }
}
@media (min-width: 1180px) {
  .c-copy [class*=wp-block] figcaption:not(:first-child) {
    margin-top: calc(
          2rem * 1
        );
    --spacer-margin-top: calc(
      2rem * 1
    );
  }
}

/* ==========================================================================
   #ELLIPSIS
   ========================================================================== */
.c-tag, .c-label, .c-filters__label, [type].c-form__input[name] {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* ==========================================================================
   #FORMS
   ========================================================================== */
.c-form__input[name] {
  display: block;
  width: 100%;
  margin: 0;
  border: 0;
  border-radius: 0;
  font-size: inherit;
  font-weight: inherit;
  line-height: normal;
  appearance: none;
}
.c-form__input[name]::placeholder {
  color: inherit;
  opacity: 1;
}
.c-form__input[name]:focus {
  outline: none;
}
[rows].c-form__input[name] {
  resize: none;
}
[type=number].c-form__input[name]::-webkit-inner-spin-button, [type=number].c-form__input[name]::-webkit-outer-spin-button {
  appearance: none;
}
.c-form__input[name]:-webkit-autofill {
  animation-delay: 1s;
  animation-name: autofill;
  animation-fill-mode: both;
}

/* ==========================================================================
   #HEADING
   ========================================================================== */
.c-tabs__link, .c-heading, .c-diagram__value {
  font-weight: bold;
  font-family: martin, "AvenirNextCondensed-Bold", "Futura-CondensedExtraBold", helveticaneue-condensedbold, "Ubuntu Condensed", "Liberation Sans Narrow", "Franklin Gothic Demi Cond", "Arial Narrow", sans-serif-condensed, arial, "Trebuchet MS", "Lucida Grande", tahoma, verdana, sans-serif;
  font-stretch: condensed;
  text-transform: uppercase;
}

.c-heading--h1 {
  font-size: 9.4rem;
  line-height: 0.73;
}
@media (min-width: 768px) {
  .c-heading--h1 {
    font-size: 11.6rem;
    line-height: 0.68;
  }
}
@media (min-width: 1024px) {
  .c-heading--h1 {
    font-size: 13.4rem;
    line-height: 0.63;
  }
}

.c-heading--h2 {
  font-size: 7.4rem;
  line-height: 0.73;
}
@media (min-width: 768px) {
  .c-heading--h2 {
    font-size: 9rem;
    line-height: 0.73;
  }
}
@media (min-width: 1024px) {
  .c-heading--h2 {
    font-size: 10rem;
    line-height: 0.7;
  }
}

.c-heading--h3 {
  font-size: 4rem;
  line-height: 0.9;
}
@media (min-width: 768px) {
  .c-heading--h3 {
    font-size: 5rem;
    line-height: 0.85;
  }
}
@media (min-width: 1180px) {
  .c-heading--h3 {
    font-size: 6rem;
    line-height: 0.8;
  }
}

.c-heading--h4 {
  font-size: 4rem;
  line-height: 0.9;
}
@media (min-width: 768px) {
  .c-heading--h4 {
    font-size: 5.2rem;
  }
}

.c-heading--h5 {
  font-size: 3.6rem;
  line-height: 1;
}
@media (min-width: 768px) {
  .c-heading--h5 {
    font-size: 4.2rem;
  }
}

.c-heading--h6 {
  font-size: 3rem;
  line-height: 1;
}
@media (min-width: 768px) {
  .c-heading--h6 {
    font-size: 3.6rem;
  }
}

/* ==========================================================================
   #LINK
   ========================================================================== */
.c-pagination__item, .c-nav-secondary__link, .c-footer__copy a, .c-links__link, .c-copy a:not([class]) {
  color: #8088d1;
}
[href].c-pagination__item:hover, [href].c-nav-secondary__link:hover, .c-footer__copy a[href]:hover, [href].c-links__link:hover, .c-copy a[href]:hover:not([class]) {
  color: #101c80;
}

.c-post__title [href], .c-excerpt__title [href], .c-card__title [href] {
  display: block;
}
.c-post__title [href]:hover, .c-excerpt__title [href]:hover, .c-card__title [href]:hover {
  color: #8088d1;
}

.c-expander, a,
button {
  transition-duration: 300ms;
  transition-property: color, background-color, border, outline, box-shadow, opacity, outline, transform;
}

/* ==========================================================================
   #PLUS SIGN
   ========================================================================== */
.c-tooltip__close, .c-tag__close, .c-program__button, .c-map__plus span, .c-filters__close, .c-expander {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: var(--plus-width, 2.2rem);
}
.c-tooltip__close::before, .c-tag__close::before, .c-program__button::before, .c-map__plus span::before, .c-filters__close::before, .c-expander::before, .c-tooltip__close::after, .c-tag__close::after, .c-program__button::after, .c-map__plus span::after, .c-filters__close::after, .c-expander::after {
  content: "";
  display: block;
  width: var(--plus-width, 2.2rem);
  height: var(--plus-height, 0.5rem);
  background: currentColor;
  transition: transform 300ms;
}
.c-tooltip__close::after, .c-tag__close::after, .c-program__button::after, .c-map__plus span::after, .c-filters__close::after, .c-expander::after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0) rotate(90deg);
}
.is-expanded .c-tooltip__close::after, .is-expanded .c-tag__close::after, .is-expanded .c-program__button::after, .is-expanded .c-map__plus span::after, .c-map__plus .is-expanded span::after, .is-expanded .c-filters__close::after, .is-expanded .c-expander::after {
  transform: translate3d(-50%, -50%, 0);
}

/* ==========================================================================
   #STYLE
   ========================================================================== */
.c-post__date, .c-newsletter__copy, .c-label, .c-copy, .c-copy [class*=wp-block] figcaption {
  font-size: 1.8rem;
  line-height: 1.56;
}

.c-copy--size-sm {
  font-size: 1.6rem;
  line-height: 1.56;
}

.u-notice, .c-post, .c-copy--size-lg {
  font-size: 2rem;
  line-height: 1.33;
}
@media (min-width: 768px) {
  .u-notice, .c-post, .c-copy--size-lg {
    font-size: 2.4rem;
    line-height: 1.25;
  }
}

.c-tag, .c-filters__clear {
  font-size: 1.4rem;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}

.c-label {
  padding: 0.1em 0.5em 0;
  background-color: rgba(183, 187, 224, 0.2);
  line-height: 1.25;
}

.c-links__link {
  display: inline-block;
  padding: 0.1em 0.25em;
  background-color: rgba(183, 187, 224, 0.2);
  font-size: 1.8rem;
  line-height: 1;
}

.c-knob {
  font-family: martin, "AvenirNextCondensed-Bold", "Futura-CondensedExtraBold", helveticaneue-condensedbold, "Ubuntu Condensed", "Liberation Sans Narrow", "Franklin Gothic Demi Cond", "Arial Narrow", sans-serif-condensed, arial, "Trebuchet MS", "Lucida Grande", tahoma, verdana, sans-serif;
  font-weight: bold;
  line-height: 0.85;
  letter-spacing: 0.12em;
  text-transform: uppercase;
}

.c-shape__label {
  font-family: "League Gothic", sans-serif;
  font-size: 6.5rem;
  font-weight: bold;
  line-height: 0.9;
  text-transform: uppercase;
}

/* ==========================================================================
   #TITLE
   ========================================================================== */
.c-title, .c-copy h1:not(.c-heading), .c-copy h2:not(.c-heading), .c-copy h3:not(.c-heading), .c-copy h4:not(.c-heading), .c-copy h5:not(.c-heading), .c-copy h6:not(.c-heading) {
  font-weight: bold;
}

.c-title--h0 {
  font-size: 3.4rem;
  line-height: 1.15;
}
@media (min-width: 540px) {
  .c-title--h0 {
    font-size: 3.6rem;
    line-height: 1.11;
  }
}
@media (min-width: 1024px) {
  .c-title--h0 {
    font-size: 4rem;
  }
}
@media (min-width: 1180px) {
  .c-title--h0 {
    font-size: 4.5rem;
  }
}

.c-title--h1, .c-copy h1:not(.c-heading) {
  font-size: 3.2rem;
  line-height: 1.225;
}
@media (min-width: 768px) {
  .c-title--h1, .c-copy h1:not(.c-heading) {
    font-size: 3.6rem;
  }
}
@media (min-width: 1024px) {
  .c-title--h1, .c-copy h1:not(.c-heading) {
    font-size: 4rem;
  }
}

.c-title--h2, .c-copy h2:not(.c-heading) {
  font-size: 2.7rem;
  line-height: 1.2;
}
@media (min-width: 768px) {
  .c-title--h2, .c-copy h2:not(.c-heading) {
    font-size: 3rem;
  }
}

.c-title--h3, .c-copy h3:not(.c-heading) {
  font-size: 2.4rem;
  line-height: 1.25;
}
@media (min-width: 768px) {
  .c-title--h3, .c-copy h3:not(.c-heading) {
    font-size: 2.7rem;
  }
}

.c-title--h4, .c-copy h4:not(.c-heading) {
  font-size: 2rem;
  line-height: 1.25;
}
@media (min-width: 768px) {
  .c-title--h4, .c-copy h4:not(.c-heading) {
    font-size: 2.4rem;
  }
}

.c-title--h5, .c-copy h5:not(.c-heading) {
  font-size: 1.8rem;
  line-height: 1.25;
}
@media (min-width: 768px) {
  .c-title--h5, .c-copy h5:not(.c-heading) {
    font-size: 2rem;
  }
}

.c-title--h6, .c-copy h6:not(.c-heading) {
  font-size: 1.8rem;
  line-height: 1.25;
}

/* ==========================================================================
   #BOX-SIZING
   ========================================================================== */
/**
 * More sensible default box-sizing:
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 */
html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

/* ==========================================================================
   #FONT-FACE
   ========================================================================== */
/**
 * Add font-display: swap to font-face blocks here
 * https://www.zachleat.com/web/comprehensive-webfonts/#font-display
 */
@font-face {
  font-family: "League Gothic";
  src: url("../assets/fonts/leaguegothic-regular-webfont.woff2") format("woff2"), url("../assets/fonts/leaguegothic-regular-webfont.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Martin;
  src: url("../assets/fonts/MARTIN-Regular.woff2") format("woff2"), url("../assets/fonts/MARTIN-Regular.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Avenir;
  src: url("../assets/fonts/AvenirLTProLight/font.woff2") format("woff2"), url("../assets/fonts/AvenirLTProLight/font.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Avenir;
  src: url("../assets/fonts/AvenirLTProRoman/font.woff2") format("woff2"), url("../assets/fonts/AvenirLTProRoman/font.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Avenir;
  src: url("../assets/fonts/AvenirLTProMedium/font.woff2") format("woff2"), url("../assets/fonts/AvenirLTProMedium/font.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Avenir;
  src: url("../assets/fonts/AvenirLTProHeavy/font.woff2") format("woff2"), url("../assets/fonts/AvenirLTProHeavy/font.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
/* ==========================================================================
   #NORMALIZE
   ========================================================================== */
* {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: 0;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  text-align: inherit;
  text-transform: inherit;
}

*,
::before,
::after {
  box-sizing: inherit;
}

a,
button,
[type=button] {
  cursor: pointer;
  text-decoration: none;
}
a:focus,
button:focus,
[type=button]:focus {
  outline: none;
}

img,
iframe {
  display: block;
  max-width: 100%;
}

ul,
ol {
  list-style: none;
}

sub,
sup {
  position: relative;
  display: inline-block;
  margin-left: 0.1em;
  font-size: 0.6em;
  line-height: 1;
  vertical-align: 0.3em;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.5em;
}

/* ==========================================================================
   #HEADING
   ========================================================================== */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

/* ==========================================================================
   #HR
   ========================================================================== */
hr {
  display: block;
}

/* ==========================================================================
   #PAGE
   ========================================================================== */
html {
  height: 100%;
  width: 100%;
  font-size: 62.5%;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-y: scroll;
  overflow-x: hidden;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

/* ==========================================================================
   #IMAGES
   ========================================================================== */
/**
 * 1. Fluid images for responsive purposes.
 * 2. Offset `alt` text from surrounding copy.
 * 3. Setting `vertical-align` removes the whitespace that appears under `img`
 *    elements when they are dropped into a page as-is. Safer alternative to
 *    using `display: block;`.
 */
img {
  font-style: italic;
  /* [2] */
  max-width: 100%;
  /* [1] */
  vertical-align: middle;
  /* [3] */
}

/**
 * 1. If a `width` and/or `height` attribute have been explicitly defined, let’s
 *    not make the image fluid.
 */
img[width],
img[height] {
  max-width: none;
}

/* ==========================================================================
   #LINKS
   ========================================================================== */
a,
button {
  cursor: pointer;
}

/* ==========================================================================
   #TABLES
   ========================================================================== */
/**
 * 1. Ensure tables fill up as much space as possible.
 */
table {
  width: 100%;
  /* [1] */
}

/*
** Vendor - Flickity
** ----------------------------------------------------------------------------- */
/* prevent page scrolling when flickity is fullscreen */
.is-flickity-fullscreen {
  overflow: hidden;
}

.flickity-enabled {
  position: relative;
}
.flickity-enabled:focus {
  outline: none;
}
.flickity-enabled.is-draggable {
  user-select: none;
}
.flickity-enabled.is-draggable .flickity-viewport {
  cursor: grab;
}
.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: grabbing;
}
.flickity-enabled.is-fade .flickity-slider > * {
  pointer-events: none;
}
.flickity-enabled.is-fade .flickity-slider > .is-selected {
  pointer-events: auto;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
  transition: height 0.25s;
}
.is-fullscreen .flickity-viewport {
  transition: none;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

.flickity-page-dots {
  position: absolute;
  top: 100%;
  left: 50%;
  display: flex;
  justify-content: center;
  gap: var(--column-gutter);
  transform: translate(-50%, -50%);
}
.flickity-page-dots .dot {
  width: 1em;
  height: 1em;
  background: #ca4900;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0.5;
  transition-property: opacity;
  transition-duration: 300ms;
}
.flickity-page-dots .dot.is-selected {
  opacity: 1;
}
.flickity-page-dots .dot:hover {
  opacity: 0.75;
}

.flickity-button {
  position: absolute;
  top: 100%;
  display: none;
  color: #ca4900;
  opacity: 0.75;
  font-size: 6rem;
}
@media (min-width: 1180px) {
  .flickity-button {
    display: block;
    top: 50%;
    font-size: 12rem;
  }
}
.flickity-button.previous {
  right: 100%;
}
@media (min-width: 1024px) {
  .flickity-button.previous {
    transform: translate(33.3%, -50%);
  }
}
.flickity-button.next {
  left: 100%;
}
@media (min-width: 1024px) {
  .flickity-button.next {
    transform: translate(-33.3%, -50%);
  }
}
.flickity-button svg {
  display: block;
  height: 1em;
  width: 1em;
  fill: currentColor;
  stroke: none;
}
.flickity-button:not([disabled]):hover {
  opacity: 1;
}
.flickity-button[disabled] {
  opacity: 0;
  pointer-events: none;
}

/*
** Vendor - Tippy
** ----------------------------------------------------------------------------- */
.tippy-box[data-theme~=ccf] {
  background-color: #f4c125;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  color: #101c80;
}

/* ==========================================================================
   #BODY
   ========================================================================== */
.o-body {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
  background: #fff;
  color: #101c80;
  font-size: 1.6em;
  font-family: avenir, sans-serif;
  font-weight: normal;
  line-height: 1.25;
}
.o-body::after {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  z-index: 24;
  background-color: rgba(128, 136, 209, 0.75);
  opacity: 0;
  pointer-events: none;
  transform: translateX(-300%);
  transition-property: opacity, transform;
  transition-duration: 600ms, 0s;
  transition-delay: 0s 600ms;
}
.o-body.has-popup-open::after {
  opacity: 1;
  transform: translateX(0);
  pointer-events: all;
  transition-delay: 0s;
}
.o-body.is-loading::after {
  opacity: 1;
  transform: translateX(0);
  pointer-events: all;
  transition-delay: 0s;
}
.o-body.is-loading::before {
  content: "";
  position: fixed;
  inset: 0;
  z-index: 25;
  background-image: url("../assets/images/loader.svg");
  background-position: center;
  background-repeat: no-repeat;
}

/* ==========================================================================
   #COMPONENT
   ========================================================================== */
.o-component {
  width: 100%;
}

/* Spacing variants
   ========================================================================== */
@media (min-width: 0) {
  .o-component--xs:not(:first-child) {
    margin-top: calc(
          2rem * 0.55
        );
    --spacer-margin-top: calc(
      2rem * 0.55
    );
  }
}
@media (min-width: 768px) {
  .o-component--xs:not(:first-child) {
    margin-top: calc(
          2rem * 0.7
        );
    --spacer-margin-top: calc(
      2rem * 0.7
    );
  }
}
@media (min-width: 1024px) {
  .o-component--xs:not(:first-child) {
    margin-top: calc(
          2rem * 0.85
        );
    --spacer-margin-top: calc(
      2rem * 0.85
    );
  }
}
@media (min-width: 1180px) {
  .o-component--xs:not(:first-child) {
    margin-top: calc(
          2rem * 1
        );
    --spacer-margin-top: calc(
      2rem * 1
    );
  }
}

@media (min-width: 0) {
  .o-component--sm:not(:first-child) {
    margin-top: calc(
          2rem * 1.1
        );
    --spacer-margin-top: calc(
      2rem * 1.1
    );
  }
}
@media (min-width: 768px) {
  .o-component--sm:not(:first-child) {
    margin-top: calc(
          2rem * 1.4
        );
    --spacer-margin-top: calc(
      2rem * 1.4
    );
  }
}
@media (min-width: 1024px) {
  .o-component--sm:not(:first-child) {
    margin-top: calc(
          2rem * 1.7
        );
    --spacer-margin-top: calc(
      2rem * 1.7
    );
  }
}
@media (min-width: 1180px) {
  .o-component--sm:not(:first-child) {
    margin-top: calc(
          2rem * 2
        );
    --spacer-margin-top: calc(
      2rem * 2
    );
  }
}

@media (min-width: 0) {
  .o-component--md:not(:first-child) {
    margin-top: calc(
          2rem * 1.65
        );
    --spacer-margin-top: calc(
      2rem * 1.65
    );
  }
}
@media (min-width: 768px) {
  .o-component--md:not(:first-child) {
    margin-top: calc(
          2rem * 2.1
        );
    --spacer-margin-top: calc(
      2rem * 2.1
    );
  }
}
@media (min-width: 1024px) {
  .o-component--md:not(:first-child) {
    margin-top: calc(
          2rem * 2.55
        );
    --spacer-margin-top: calc(
      2rem * 2.55
    );
  }
}
@media (min-width: 1180px) {
  .o-component--md:not(:first-child) {
    margin-top: calc(
          2rem * 3
        );
    --spacer-margin-top: calc(
      2rem * 3
    );
  }
}

@media (min-width: 0) {
  .o-component--lg:not(:first-child) {
    margin-top: calc(
          2rem * 2.2
        );
    --spacer-margin-top: calc(
      2rem * 2.2
    );
  }
}
@media (min-width: 768px) {
  .o-component--lg:not(:first-child) {
    margin-top: calc(
          2rem * 2.8
        );
    --spacer-margin-top: calc(
      2rem * 2.8
    );
  }
}
@media (min-width: 1024px) {
  .o-component--lg:not(:first-child) {
    margin-top: calc(
          2rem * 3.4
        );
    --spacer-margin-top: calc(
      2rem * 3.4
    );
  }
}
@media (min-width: 1180px) {
  .o-component--lg:not(:first-child) {
    margin-top: calc(
          2rem * 4
        );
    --spacer-margin-top: calc(
      2rem * 4
    );
  }
}

@media (min-width: 0) {
  .o-component--xl:not(:first-child) {
    margin-top: calc(
          2rem * 2.75
        );
    --spacer-margin-top: calc(
      2rem * 2.75
    );
  }
}
@media (min-width: 768px) {
  .o-component--xl:not(:first-child) {
    margin-top: calc(
          2rem * 3.5
        );
    --spacer-margin-top: calc(
      2rem * 3.5
    );
  }
}
@media (min-width: 1024px) {
  .o-component--xl:not(:first-child) {
    margin-top: calc(
          2rem * 4.25
        );
    --spacer-margin-top: calc(
      2rem * 4.25
    );
  }
}
@media (min-width: 1180px) {
  .o-component--xl:not(:first-child) {
    margin-top: calc(
          2rem * 5
        );
    --spacer-margin-top: calc(
      2rem * 5
    );
  }
}

/* ==========================================================================
   #CONTAINER
   ========================================================================== */
/**
 * Page-level constraining and wrapping elements.
 */
.o-container--fluid {
  max-width: none;
}

/* ==========================================================================
   #GRID
   ========================================================================== */
.o-grid {
  display: grid;
  row-gap: calc(var(--column-gutter) * 2);
  column-gap: var(--column-gutter);
}
@media (min-width: 768px) {
  .o-grid {
    row-gap: calc(var(--column-gutter) * 3);
  }
}
.o-grid--left {
  direction: ltr;
}
.o-grid--right {
  direction: rtl;
}
@media (min-width: 0) {
  .o-grid--xs-1 {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (min-width: 0) {
  .o-grid--xs-2 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 0) {
  .o-grid--xs-3 {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 0) {
  .o-grid--xs-4 {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 0) {
  .o-grid--xs-5 {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media (min-width: 0) {
  .o-grid--xs-6 {
    grid-template-columns: repeat(6, 1fr);
  }
}
@media (min-width: 540px) {
  .o-grid--sm-1 {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (min-width: 540px) {
  .o-grid--sm-2 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 540px) {
  .o-grid--sm-3 {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 540px) {
  .o-grid--sm-4 {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 540px) {
  .o-grid--sm-5 {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media (min-width: 540px) {
  .o-grid--sm-6 {
    grid-template-columns: repeat(6, 1fr);
  }
}
@media (min-width: 768px) {
  .o-grid--md-1 {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (min-width: 768px) {
  .o-grid--md-2 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 768px) {
  .o-grid--md-3 {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 768px) {
  .o-grid--md-4 {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 768px) {
  .o-grid--md-5 {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media (min-width: 768px) {
  .o-grid--md-6 {
    grid-template-columns: repeat(6, 1fr);
  }
}
@media (min-width: 1024px) {
  .o-grid--lg-1 {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (min-width: 1024px) {
  .o-grid--lg-2 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1024px) {
  .o-grid--lg-3 {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 1024px) {
  .o-grid--lg-4 {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 1024px) {
  .o-grid--lg-5 {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media (min-width: 1024px) {
  .o-grid--lg-6 {
    grid-template-columns: repeat(6, 1fr);
  }
}
@media (min-width: 1180px) {
  .o-grid--xl-1 {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (min-width: 1180px) {
  .o-grid--xl-2 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1180px) {
  .o-grid--xl-3 {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 1180px) {
  .o-grid--xl-4 {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 1180px) {
  .o-grid--xl-5 {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media (min-width: 1180px) {
  .o-grid--xl-6 {
    grid-template-columns: repeat(6, 1fr);
  }
}
.o-grid--gap-sm {
  gap: calc(var(--column-gutter) * 1);
}
@media (min-width: 768px) {
  .o-grid--gap-sm {
    gap: calc(var(--column-gutter) * 1.5);
  }
}
@media (min-width: 768px) {
  .o-grid--gap-lg {
    row-gap: calc(var(--column-gutter) * 3);
    column-gap: calc(var(--column-gutter) * 2);
  }
}
@media (min-width: 1180px) {
  .o-grid--gap-lg {
    row-gap: calc(var(--column-gutter) * 4);
    column-gap: calc(var(--column-gutter) * 3);
  }
}
.o-grid--valign-middle {
  align-items: center;
}

.o-grid__item {
  width: 100%;
}
.o-grid__item--full {
  grid-column: 1/-1;
  width: 100%;
}
@media (min-width: 0) {
  .o-grid__item--xs-1 {
    grid-column: span 1;
  }
}
@media (min-width: 0) {
  .o-grid__item--xs-2 {
    grid-column: span 2;
  }
}
@media (min-width: 0) {
  .o-grid__item--xs-3 {
    grid-column: span 3;
  }
}
@media (min-width: 0) {
  .o-grid__item--xs-4 {
    grid-column: span 4;
  }
}
@media (min-width: 0) {
  .o-grid__item--xs-5 {
    grid-column: span 5;
  }
}
@media (min-width: 0) {
  .o-grid__item--xs-6 {
    grid-column: span 6;
  }
}
@media (min-width: 540px) {
  .o-grid__item--sm-1 {
    grid-column: span 1;
  }
}
@media (min-width: 540px) {
  .o-grid__item--sm-2 {
    grid-column: span 2;
  }
}
@media (min-width: 540px) {
  .o-grid__item--sm-3 {
    grid-column: span 3;
  }
}
@media (min-width: 540px) {
  .o-grid__item--sm-4 {
    grid-column: span 4;
  }
}
@media (min-width: 540px) {
  .o-grid__item--sm-5 {
    grid-column: span 5;
  }
}
@media (min-width: 540px) {
  .o-grid__item--sm-6 {
    grid-column: span 6;
  }
}
@media (min-width: 768px) {
  .o-grid__item--md-1 {
    grid-column: span 1;
  }
}
@media (min-width: 768px) {
  .o-grid__item--md-2 {
    grid-column: span 2;
  }
}
@media (min-width: 768px) {
  .o-grid__item--md-3 {
    grid-column: span 3;
  }
}
@media (min-width: 768px) {
  .o-grid__item--md-4 {
    grid-column: span 4;
  }
}
@media (min-width: 768px) {
  .o-grid__item--md-5 {
    grid-column: span 5;
  }
}
@media (min-width: 768px) {
  .o-grid__item--md-6 {
    grid-column: span 6;
  }
}
@media (min-width: 1024px) {
  .o-grid__item--lg-1 {
    grid-column: span 1;
  }
}
@media (min-width: 1024px) {
  .o-grid__item--lg-2 {
    grid-column: span 2;
  }
}
@media (min-width: 1024px) {
  .o-grid__item--lg-3 {
    grid-column: span 3;
  }
}
@media (min-width: 1024px) {
  .o-grid__item--lg-4 {
    grid-column: span 4;
  }
}
@media (min-width: 1024px) {
  .o-grid__item--lg-5 {
    grid-column: span 5;
  }
}
@media (min-width: 1024px) {
  .o-grid__item--lg-6 {
    grid-column: span 6;
  }
}
@media (min-width: 1180px) {
  .o-grid__item--xl-1 {
    grid-column: span 1;
  }
}
@media (min-width: 1180px) {
  .o-grid__item--xl-2 {
    grid-column: span 2;
  }
}
@media (min-width: 1180px) {
  .o-grid__item--xl-3 {
    grid-column: span 3;
  }
}
@media (min-width: 1180px) {
  .o-grid__item--xl-4 {
    grid-column: span 4;
  }
}
@media (min-width: 1180px) {
  .o-grid__item--xl-5 {
    grid-column: span 5;
  }
}
@media (min-width: 1180px) {
  .o-grid__item--xl-6 {
    grid-column: span 6;
  }
}

/* ==========================================================================
   #LAYOUT
   ========================================================================== */
/* Simple flexbox layout system */
.o-layout {
  display: block;
}
@media (min-width: 768px) {
  .o-layout {
    display: flex;
  }
}

.o-layout__item {
  flex: 1;
}

.o-layout--guttered {
  justify-content: space-between;
}
.o-layout--guttered > .o-layout__item {
  flex: none;
}

@media (min-width: 768px) {
  .o-layout--2 > .o-layout__item {
    width: 49%;
  }
}

@media (min-width: 768px) {
  .o-layout--3 > .o-layout__item {
    width: 32%;
  }
}

@media (min-width: 768px) {
  .o-layout--4 > .o-layout__item {
    width: 24%;
  }
}

/* ==========================================================================
   #SECTION
   ========================================================================== */
.o-section {
  --section-bg-color: #fff;
  --section-color: #101c80;
  position: relative;
  background-color: var(--section-bg-color);
  color: var(--section-color);
}

.o-section__button-wrapper {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1;
  max-width: 124rem;
  margin-right: auto;
  margin-left: auto;
}

.o-section__button {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (min-width: 768px) {
  .o-section__button {
    left: var(--container-gutter);
    transform: translateY(-50%);
  }
}
@media (min-width: 1024px) {
  .o-section__button {
    left: 10%;
  }
}

@media (min-width: 1024px) {
  .o-section__link {
    position: absolute;
    right: 0;
    bottom: calc(var(--spacer-padding-bottom) / 2);
    z-index: 1;
    transform: translateY(50%);
  }
}
.o-section__link .o-container {
  display: flex;
  justify-content: center;
}
@media (min-width: 1024px) {
  .o-section__link .o-container {
    justify-content: flex-end;
  }
}

.o-section__content {
  display: flex;
  flex-direction: column;
  gap: calc(var(--column-gutter) * 1);
  max-width: var(--content-width);
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 768px) {
  .o-section__content {
    gap: calc(var(--column-gutter) * 1.5);
  }
}

/* Color variants
   ========================================================================== */
.o-section--color-white {
  --section-bg-color: #fff;
}
.o-section--color-white:not(.o-section--acf-image) + .o-section--color-white:not(.o-section--separated):not(.o-section--acf-image) {
  padding-top: 0;
}

.o-section--color-shade {
  --section-bg-color: #e4e7ff;
}
.o-section--color-shade:not(.o-section--acf-image) + .o-section--color-shade:not(.o-section--separated):not(.o-section--acf-image) {
  padding-top: 0;
}

.o-section--color-titan {
  --section-bg-color: #eff1ff;
}
.o-section--color-titan:not(.o-section--acf-image) + .o-section--color-titan:not(.o-section--separated):not(.o-section--acf-image) {
  padding-top: 0;
}

.o-section--color-powder {
  --section-bg-color: #fff9f9;
}
.o-section--color-powder:not(.o-section--acf-image) + .o-section--color-powder:not(.o-section--separated):not(.o-section--acf-image) {
  padding-top: 0;
}

.o-section--color-lt-green {
  --section-bg-color: #eeefd0;
}
.o-section--color-lt-green:not(.o-section--acf-image) + .o-section--color-lt-green:not(.o-section--separated):not(.o-section--acf-image) {
  padding-top: 0;
}

.o-section--color-green {
  --section-bg-color: #3f5e4d;
}
.o-section--color-green:not(.o-section--acf-image) + .o-section--color-green:not(.o-section--separated):not(.o-section--acf-image) {
  padding-top: 0;
}

.o-section--color-red {
  --section-bg-color: #ca4900;
}
.o-section--color-red:not(.o-section--acf-image) + .o-section--color-red:not(.o-section--separated):not(.o-section--acf-image) {
  padding-top: 0;
}

.o-section--color-navy {
  --section-bg-color: #101c80;
}
.o-section--color-navy:not(.o-section--acf-image) + .o-section--color-navy:not(.o-section--separated):not(.o-section--acf-image) {
  padding-top: 0;
}

.o-section--color-green,
.o-section--color-red,
.o-section--color-navy {
  --section-color: #fff;
  --section-title-color: #fff;
}

.o-section--color-red .c-button {
  --button-bg-color: #ca4900;
  --button-border-color: #fff;
  --button-color: #fff;
}

/* Spacing variants
   ========================================================================== */
@media (min-width: 0) {
  .o-section--p-xs {
    padding-top: calc(
          2rem * 1.1
        );
    --spacer-padding-top: calc(
      2rem * 1.1
    );
  }
}
@media (min-width: 768px) {
  .o-section--p-xs {
    padding-top: calc(
          2rem * 1.4
        );
    --spacer-padding-top: calc(
      2rem * 1.4
    );
  }
}
@media (min-width: 1024px) {
  .o-section--p-xs {
    padding-top: calc(
          2rem * 1.7
        );
    --spacer-padding-top: calc(
      2rem * 1.7
    );
  }
}
@media (min-width: 1180px) {
  .o-section--p-xs {
    padding-top: calc(
          2rem * 2
        );
    --spacer-padding-top: calc(
      2rem * 2
    );
  }
}
@media (min-width: 0) {
  .o-section--p-xs {
    padding-bottom: calc(
          2rem * 1.1
        );
    --spacer-padding-bottom: calc(
      2rem * 1.1
    );
  }
}
@media (min-width: 768px) {
  .o-section--p-xs {
    padding-bottom: calc(
          2rem * 1.4
        );
    --spacer-padding-bottom: calc(
      2rem * 1.4
    );
  }
}
@media (min-width: 1024px) {
  .o-section--p-xs {
    padding-bottom: calc(
          2rem * 1.7
        );
    --spacer-padding-bottom: calc(
      2rem * 1.7
    );
  }
}
@media (min-width: 1180px) {
  .o-section--p-xs {
    padding-bottom: calc(
          2rem * 2
        );
    --spacer-padding-bottom: calc(
      2rem * 2
    );
  }
}

@media (min-width: 0) {
  .o-section--pt-xs {
    padding-top: calc(
          2rem * 1.1
        );
    --spacer-padding-top: calc(
      2rem * 1.1
    );
  }
}
@media (min-width: 768px) {
  .o-section--pt-xs {
    padding-top: calc(
          2rem * 1.4
        );
    --spacer-padding-top: calc(
      2rem * 1.4
    );
  }
}
@media (min-width: 1024px) {
  .o-section--pt-xs {
    padding-top: calc(
          2rem * 1.7
        );
    --spacer-padding-top: calc(
      2rem * 1.7
    );
  }
}
@media (min-width: 1180px) {
  .o-section--pt-xs {
    padding-top: calc(
          2rem * 2
        );
    --spacer-padding-top: calc(
      2rem * 2
    );
  }
}

@media (min-width: 0) {
  .o-section--pb-xs {
    padding-bottom: calc(
          2rem * 1.1
        );
    --spacer-padding-bottom: calc(
      2rem * 1.1
    );
  }
}
@media (min-width: 768px) {
  .o-section--pb-xs {
    padding-bottom: calc(
          2rem * 1.4
        );
    --spacer-padding-bottom: calc(
      2rem * 1.4
    );
  }
}
@media (min-width: 1024px) {
  .o-section--pb-xs {
    padding-bottom: calc(
          2rem * 1.7
        );
    --spacer-padding-bottom: calc(
      2rem * 1.7
    );
  }
}
@media (min-width: 1180px) {
  .o-section--pb-xs {
    padding-bottom: calc(
          2rem * 2
        );
    --spacer-padding-bottom: calc(
      2rem * 2
    );
  }
}

@media (min-width: 0) {
  .o-section--p-sm {
    padding-top: calc(
          2rem * 1.65
        );
    --spacer-padding-top: calc(
      2rem * 1.65
    );
  }
}
@media (min-width: 768px) {
  .o-section--p-sm {
    padding-top: calc(
          2rem * 2.1
        );
    --spacer-padding-top: calc(
      2rem * 2.1
    );
  }
}
@media (min-width: 1024px) {
  .o-section--p-sm {
    padding-top: calc(
          2rem * 2.55
        );
    --spacer-padding-top: calc(
      2rem * 2.55
    );
  }
}
@media (min-width: 1180px) {
  .o-section--p-sm {
    padding-top: calc(
          2rem * 3
        );
    --spacer-padding-top: calc(
      2rem * 3
    );
  }
}
@media (min-width: 0) {
  .o-section--p-sm {
    padding-bottom: calc(
          2rem * 1.65
        );
    --spacer-padding-bottom: calc(
      2rem * 1.65
    );
  }
}
@media (min-width: 768px) {
  .o-section--p-sm {
    padding-bottom: calc(
          2rem * 2.1
        );
    --spacer-padding-bottom: calc(
      2rem * 2.1
    );
  }
}
@media (min-width: 1024px) {
  .o-section--p-sm {
    padding-bottom: calc(
          2rem * 2.55
        );
    --spacer-padding-bottom: calc(
      2rem * 2.55
    );
  }
}
@media (min-width: 1180px) {
  .o-section--p-sm {
    padding-bottom: calc(
          2rem * 3
        );
    --spacer-padding-bottom: calc(
      2rem * 3
    );
  }
}

@media (min-width: 0) {
  .o-section--pt-sm {
    padding-top: calc(
          2rem * 1.65
        );
    --spacer-padding-top: calc(
      2rem * 1.65
    );
  }
}
@media (min-width: 768px) {
  .o-section--pt-sm {
    padding-top: calc(
          2rem * 2.1
        );
    --spacer-padding-top: calc(
      2rem * 2.1
    );
  }
}
@media (min-width: 1024px) {
  .o-section--pt-sm {
    padding-top: calc(
          2rem * 2.55
        );
    --spacer-padding-top: calc(
      2rem * 2.55
    );
  }
}
@media (min-width: 1180px) {
  .o-section--pt-sm {
    padding-top: calc(
          2rem * 3
        );
    --spacer-padding-top: calc(
      2rem * 3
    );
  }
}

@media (min-width: 0) {
  .o-section--pb-sm {
    padding-bottom: calc(
          2rem * 1.65
        );
    --spacer-padding-bottom: calc(
      2rem * 1.65
    );
  }
}
@media (min-width: 768px) {
  .o-section--pb-sm {
    padding-bottom: calc(
          2rem * 2.1
        );
    --spacer-padding-bottom: calc(
      2rem * 2.1
    );
  }
}
@media (min-width: 1024px) {
  .o-section--pb-sm {
    padding-bottom: calc(
          2rem * 2.55
        );
    --spacer-padding-bottom: calc(
      2rem * 2.55
    );
  }
}
@media (min-width: 1180px) {
  .o-section--pb-sm {
    padding-bottom: calc(
          2rem * 3
        );
    --spacer-padding-bottom: calc(
      2rem * 3
    );
  }
}

@media (min-width: 0) {
  .o-section--p-md {
    padding-top: calc(
          2rem * 2.2
        );
    --spacer-padding-top: calc(
      2rem * 2.2
    );
  }
}
@media (min-width: 768px) {
  .o-section--p-md {
    padding-top: calc(
          2rem * 2.8
        );
    --spacer-padding-top: calc(
      2rem * 2.8
    );
  }
}
@media (min-width: 1024px) {
  .o-section--p-md {
    padding-top: calc(
          2rem * 3.4
        );
    --spacer-padding-top: calc(
      2rem * 3.4
    );
  }
}
@media (min-width: 1180px) {
  .o-section--p-md {
    padding-top: calc(
          2rem * 4
        );
    --spacer-padding-top: calc(
      2rem * 4
    );
  }
}
@media (min-width: 0) {
  .o-section--p-md {
    padding-bottom: calc(
          2rem * 2.2
        );
    --spacer-padding-bottom: calc(
      2rem * 2.2
    );
  }
}
@media (min-width: 768px) {
  .o-section--p-md {
    padding-bottom: calc(
          2rem * 2.8
        );
    --spacer-padding-bottom: calc(
      2rem * 2.8
    );
  }
}
@media (min-width: 1024px) {
  .o-section--p-md {
    padding-bottom: calc(
          2rem * 3.4
        );
    --spacer-padding-bottom: calc(
      2rem * 3.4
    );
  }
}
@media (min-width: 1180px) {
  .o-section--p-md {
    padding-bottom: calc(
          2rem * 4
        );
    --spacer-padding-bottom: calc(
      2rem * 4
    );
  }
}

@media (min-width: 0) {
  .o-section--pt-md {
    padding-top: calc(
          2rem * 2.2
        );
    --spacer-padding-top: calc(
      2rem * 2.2
    );
  }
}
@media (min-width: 768px) {
  .o-section--pt-md {
    padding-top: calc(
          2rem * 2.8
        );
    --spacer-padding-top: calc(
      2rem * 2.8
    );
  }
}
@media (min-width: 1024px) {
  .o-section--pt-md {
    padding-top: calc(
          2rem * 3.4
        );
    --spacer-padding-top: calc(
      2rem * 3.4
    );
  }
}
@media (min-width: 1180px) {
  .o-section--pt-md {
    padding-top: calc(
          2rem * 4
        );
    --spacer-padding-top: calc(
      2rem * 4
    );
  }
}

@media (min-width: 0) {
  .o-section--pb-md {
    padding-bottom: calc(
          2rem * 2.2
        );
    --spacer-padding-bottom: calc(
      2rem * 2.2
    );
  }
}
@media (min-width: 768px) {
  .o-section--pb-md {
    padding-bottom: calc(
          2rem * 2.8
        );
    --spacer-padding-bottom: calc(
      2rem * 2.8
    );
  }
}
@media (min-width: 1024px) {
  .o-section--pb-md {
    padding-bottom: calc(
          2rem * 3.4
        );
    --spacer-padding-bottom: calc(
      2rem * 3.4
    );
  }
}
@media (min-width: 1180px) {
  .o-section--pb-md {
    padding-bottom: calc(
          2rem * 4
        );
    --spacer-padding-bottom: calc(
      2rem * 4
    );
  }
}

@media (min-width: 0) {
  .o-section--p-lg {
    padding-top: calc(
          2rem * 2.75
        );
    --spacer-padding-top: calc(
      2rem * 2.75
    );
  }
}
@media (min-width: 768px) {
  .o-section--p-lg {
    padding-top: calc(
          2rem * 3.5
        );
    --spacer-padding-top: calc(
      2rem * 3.5
    );
  }
}
@media (min-width: 1024px) {
  .o-section--p-lg {
    padding-top: calc(
          2rem * 4.25
        );
    --spacer-padding-top: calc(
      2rem * 4.25
    );
  }
}
@media (min-width: 1180px) {
  .o-section--p-lg {
    padding-top: calc(
          2rem * 5
        );
    --spacer-padding-top: calc(
      2rem * 5
    );
  }
}
@media (min-width: 0) {
  .o-section--p-lg {
    padding-bottom: calc(
          2rem * 2.75
        );
    --spacer-padding-bottom: calc(
      2rem * 2.75
    );
  }
}
@media (min-width: 768px) {
  .o-section--p-lg {
    padding-bottom: calc(
          2rem * 3.5
        );
    --spacer-padding-bottom: calc(
      2rem * 3.5
    );
  }
}
@media (min-width: 1024px) {
  .o-section--p-lg {
    padding-bottom: calc(
          2rem * 4.25
        );
    --spacer-padding-bottom: calc(
      2rem * 4.25
    );
  }
}
@media (min-width: 1180px) {
  .o-section--p-lg {
    padding-bottom: calc(
          2rem * 5
        );
    --spacer-padding-bottom: calc(
      2rem * 5
    );
  }
}

@media (min-width: 0) {
  .o-section--pt-lg {
    padding-top: calc(
          2rem * 2.75
        );
    --spacer-padding-top: calc(
      2rem * 2.75
    );
  }
}
@media (min-width: 768px) {
  .o-section--pt-lg {
    padding-top: calc(
          2rem * 3.5
        );
    --spacer-padding-top: calc(
      2rem * 3.5
    );
  }
}
@media (min-width: 1024px) {
  .o-section--pt-lg {
    padding-top: calc(
          2rem * 4.25
        );
    --spacer-padding-top: calc(
      2rem * 4.25
    );
  }
}
@media (min-width: 1180px) {
  .o-section--pt-lg {
    padding-top: calc(
          2rem * 5
        );
    --spacer-padding-top: calc(
      2rem * 5
    );
  }
}

@media (min-width: 0) {
  .o-section--pb-lg {
    padding-bottom: calc(
          2rem * 2.75
        );
    --spacer-padding-bottom: calc(
      2rem * 2.75
    );
  }
}
@media (min-width: 768px) {
  .o-section--pb-lg {
    padding-bottom: calc(
          2rem * 3.5
        );
    --spacer-padding-bottom: calc(
      2rem * 3.5
    );
  }
}
@media (min-width: 1024px) {
  .o-section--pb-lg {
    padding-bottom: calc(
          2rem * 4.25
        );
    --spacer-padding-bottom: calc(
      2rem * 4.25
    );
  }
}
@media (min-width: 1180px) {
  .o-section--pb-lg {
    padding-bottom: calc(
          2rem * 5
        );
    --spacer-padding-bottom: calc(
      2rem * 5
    );
  }
}

@media (min-width: 0) {
  .o-section--p-xl {
    padding-top: calc(
          2rem * 3.3
        );
    --spacer-padding-top: calc(
      2rem * 3.3
    );
  }
}
@media (min-width: 768px) {
  .o-section--p-xl {
    padding-top: calc(
          2rem * 4.2
        );
    --spacer-padding-top: calc(
      2rem * 4.2
    );
  }
}
@media (min-width: 1024px) {
  .o-section--p-xl {
    padding-top: calc(
          2rem * 5.1
        );
    --spacer-padding-top: calc(
      2rem * 5.1
    );
  }
}
@media (min-width: 1180px) {
  .o-section--p-xl {
    padding-top: calc(
          2rem * 6
        );
    --spacer-padding-top: calc(
      2rem * 6
    );
  }
}
@media (min-width: 0) {
  .o-section--p-xl {
    padding-bottom: calc(
          2rem * 3.3
        );
    --spacer-padding-bottom: calc(
      2rem * 3.3
    );
  }
}
@media (min-width: 768px) {
  .o-section--p-xl {
    padding-bottom: calc(
          2rem * 4.2
        );
    --spacer-padding-bottom: calc(
      2rem * 4.2
    );
  }
}
@media (min-width: 1024px) {
  .o-section--p-xl {
    padding-bottom: calc(
          2rem * 5.1
        );
    --spacer-padding-bottom: calc(
      2rem * 5.1
    );
  }
}
@media (min-width: 1180px) {
  .o-section--p-xl {
    padding-bottom: calc(
          2rem * 6
        );
    --spacer-padding-bottom: calc(
      2rem * 6
    );
  }
}

@media (min-width: 0) {
  .o-section--pt-xl {
    padding-top: calc(
          2rem * 3.3
        );
    --spacer-padding-top: calc(
      2rem * 3.3
    );
  }
}
@media (min-width: 768px) {
  .o-section--pt-xl {
    padding-top: calc(
          2rem * 4.2
        );
    --spacer-padding-top: calc(
      2rem * 4.2
    );
  }
}
@media (min-width: 1024px) {
  .o-section--pt-xl {
    padding-top: calc(
          2rem * 5.1
        );
    --spacer-padding-top: calc(
      2rem * 5.1
    );
  }
}
@media (min-width: 1180px) {
  .o-section--pt-xl {
    padding-top: calc(
          2rem * 6
        );
    --spacer-padding-top: calc(
      2rem * 6
    );
  }
}

@media (min-width: 0) {
  .o-section--pb-xl {
    padding-bottom: calc(
          2rem * 3.3
        );
    --spacer-padding-bottom: calc(
      2rem * 3.3
    );
  }
}
@media (min-width: 768px) {
  .o-section--pb-xl {
    padding-bottom: calc(
          2rem * 4.2
        );
    --spacer-padding-bottom: calc(
      2rem * 4.2
    );
  }
}
@media (min-width: 1024px) {
  .o-section--pb-xl {
    padding-bottom: calc(
          2rem * 5.1
        );
    --spacer-padding-bottom: calc(
      2rem * 5.1
    );
  }
}
@media (min-width: 1180px) {
  .o-section--pb-xl {
    padding-bottom: calc(
          2rem * 6
        );
    --spacer-padding-bottom: calc(
      2rem * 6
    );
  }
}

@media (min-width: 0) {
  .o-section--p-xxl {
    padding-top: calc(
          2rem * 3.85
        );
    --spacer-padding-top: calc(
      2rem * 3.85
    );
  }
}
@media (min-width: 768px) {
  .o-section--p-xxl {
    padding-top: calc(
          2rem * 4.9
        );
    --spacer-padding-top: calc(
      2rem * 4.9
    );
  }
}
@media (min-width: 1024px) {
  .o-section--p-xxl {
    padding-top: calc(
          2rem * 5.95
        );
    --spacer-padding-top: calc(
      2rem * 5.95
    );
  }
}
@media (min-width: 1180px) {
  .o-section--p-xxl {
    padding-top: calc(
          2rem * 7
        );
    --spacer-padding-top: calc(
      2rem * 7
    );
  }
}
@media (min-width: 0) {
  .o-section--p-xxl {
    padding-bottom: calc(
          2rem * 3.85
        );
    --spacer-padding-bottom: calc(
      2rem * 3.85
    );
  }
}
@media (min-width: 768px) {
  .o-section--p-xxl {
    padding-bottom: calc(
          2rem * 4.9
        );
    --spacer-padding-bottom: calc(
      2rem * 4.9
    );
  }
}
@media (min-width: 1024px) {
  .o-section--p-xxl {
    padding-bottom: calc(
          2rem * 5.95
        );
    --spacer-padding-bottom: calc(
      2rem * 5.95
    );
  }
}
@media (min-width: 1180px) {
  .o-section--p-xxl {
    padding-bottom: calc(
          2rem * 7
        );
    --spacer-padding-bottom: calc(
      2rem * 7
    );
  }
}

@media (min-width: 0) {
  .o-section--pt-xxl {
    padding-top: calc(
          2rem * 3.85
        );
    --spacer-padding-top: calc(
      2rem * 3.85
    );
  }
}
@media (min-width: 768px) {
  .o-section--pt-xxl {
    padding-top: calc(
          2rem * 4.9
        );
    --spacer-padding-top: calc(
      2rem * 4.9
    );
  }
}
@media (min-width: 1024px) {
  .o-section--pt-xxl {
    padding-top: calc(
          2rem * 5.95
        );
    --spacer-padding-top: calc(
      2rem * 5.95
    );
  }
}
@media (min-width: 1180px) {
  .o-section--pt-xxl {
    padding-top: calc(
          2rem * 7
        );
    --spacer-padding-top: calc(
      2rem * 7
    );
  }
}

@media (min-width: 0) {
  .o-section--pb-xxl {
    padding-bottom: calc(
          2rem * 3.85
        );
    --spacer-padding-bottom: calc(
      2rem * 3.85
    );
  }
}
@media (min-width: 768px) {
  .o-section--pb-xxl {
    padding-bottom: calc(
          2rem * 4.9
        );
    --spacer-padding-bottom: calc(
      2rem * 4.9
    );
  }
}
@media (min-width: 1024px) {
  .o-section--pb-xxl {
    padding-bottom: calc(
          2rem * 5.95
        );
    --spacer-padding-bottom: calc(
      2rem * 5.95
    );
  }
}
@media (min-width: 1180px) {
  .o-section--pb-xxl {
    padding-bottom: calc(
          2rem * 7
        );
    --spacer-padding-bottom: calc(
      2rem * 7
    );
  }
}

@media (min-width: 0) {
  .o-section--p-xxxl {
    padding-top: calc(
          2rem * 4.4
        );
    --spacer-padding-top: calc(
      2rem * 4.4
    );
  }
}
@media (min-width: 768px) {
  .o-section--p-xxxl {
    padding-top: calc(
          2rem * 5.6
        );
    --spacer-padding-top: calc(
      2rem * 5.6
    );
  }
}
@media (min-width: 1024px) {
  .o-section--p-xxxl {
    padding-top: calc(
          2rem * 6.8
        );
    --spacer-padding-top: calc(
      2rem * 6.8
    );
  }
}
@media (min-width: 1180px) {
  .o-section--p-xxxl {
    padding-top: calc(
          2rem * 8
        );
    --spacer-padding-top: calc(
      2rem * 8
    );
  }
}
@media (min-width: 0) {
  .o-section--p-xxxl {
    padding-bottom: calc(
          2rem * 4.4
        );
    --spacer-padding-bottom: calc(
      2rem * 4.4
    );
  }
}
@media (min-width: 768px) {
  .o-section--p-xxxl {
    padding-bottom: calc(
          2rem * 5.6
        );
    --spacer-padding-bottom: calc(
      2rem * 5.6
    );
  }
}
@media (min-width: 1024px) {
  .o-section--p-xxxl {
    padding-bottom: calc(
          2rem * 6.8
        );
    --spacer-padding-bottom: calc(
      2rem * 6.8
    );
  }
}
@media (min-width: 1180px) {
  .o-section--p-xxxl {
    padding-bottom: calc(
          2rem * 8
        );
    --spacer-padding-bottom: calc(
      2rem * 8
    );
  }
}

@media (min-width: 0) {
  .o-section--pt-xxxl {
    padding-top: calc(
          2rem * 4.4
        );
    --spacer-padding-top: calc(
      2rem * 4.4
    );
  }
}
@media (min-width: 768px) {
  .o-section--pt-xxxl {
    padding-top: calc(
          2rem * 5.6
        );
    --spacer-padding-top: calc(
      2rem * 5.6
    );
  }
}
@media (min-width: 1024px) {
  .o-section--pt-xxxl {
    padding-top: calc(
          2rem * 6.8
        );
    --spacer-padding-top: calc(
      2rem * 6.8
    );
  }
}
@media (min-width: 1180px) {
  .o-section--pt-xxxl {
    padding-top: calc(
          2rem * 8
        );
    --spacer-padding-top: calc(
      2rem * 8
    );
  }
}

@media (min-width: 0) {
  .o-section--pb-xxxl {
    padding-bottom: calc(
          2rem * 4.4
        );
    --spacer-padding-bottom: calc(
      2rem * 4.4
    );
  }
}
@media (min-width: 768px) {
  .o-section--pb-xxxl {
    padding-bottom: calc(
          2rem * 5.6
        );
    --spacer-padding-bottom: calc(
      2rem * 5.6
    );
  }
}
@media (min-width: 1024px) {
  .o-section--pb-xxxl {
    padding-bottom: calc(
          2rem * 6.8
        );
    --spacer-padding-bottom: calc(
      2rem * 6.8
    );
  }
}
@media (min-width: 1180px) {
  .o-section--pb-xxxl {
    padding-bottom: calc(
          2rem * 8
        );
    --spacer-padding-bottom: calc(
      2rem * 8
    );
  }
}

/* Other variants
   ========================================================================== */
@media (max-width: 767.98px) {
  .o-section--has-button {
    padding-bottom: calc(var(--spacer-padding-bottom) * 1.75);
  }
}
.o-section--has-button + .o-section {
  padding-top: calc(var(--spacer-padding-top) * 1.75);
}
@media (min-width: 1024px) {
  .o-section--has-button + .o-section {
    padding-top: calc(var(--spacer-padding-top) * 2);
  }
}

.o-section--separated {
  border-top: 1px solid #e4e7ff;
}

.o-section--nested {
  margin-left: calc((50vw - 50%) * -1);
  margin-right: calc((50vw - 50%) * -1);
}

/* ==========================================================================
   #WRAPPER
   ========================================================================== */
/**
 * Page-level constraining and wrapping elements.
 */
.o-wrapper {
  position: relative;
  z-index: 1;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition-property: opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}
.has-menu-open .o-wrapper {
  opacity: 0.5;
  transition-delay: 0.41s;
}

/* ==========================================================================
   #ACCORDION
   ========================================================================== */
.c-accordion {
  position: relative;
}
.c-accordion.is-expanded {
  padding-bottom: var(--spacer-padding-bottom);
}

.c-accordion__head--expandable {
  padding-bottom: var(--spacer-padding-bottom);
}
.is-expanded .c-accordion__head--expandable {
  padding-bottom: 0;
}
@media (min-width: 768px) {
  .c-accordion__head--expandable {
    padding-bottom: 0;
  }
}

.c-accordion__copy {
  padding-right: 12.5%;
}
@media (max-width: 1179.98px) {
  .c-accordion__copy {
    padding-right: 15%;
    font-size: 2rem;
    line-height: 1.3;
  }
}

.c-accordion__button {
  --expander-bg-color: #101c80;
  --expander-color: var(--section-bg-color);
  --expander-active-bg-color: #8088d1;
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: -0.5em;
}

.c-accordion__wrapper {
  display: none;
}
.is-expanded .c-accordion__wrapper {
  display: block;
}

.c-accordion__block {
  margin: calc(var(--spacer-padding-bottom) * 0.75) calc(var(--container-gutter) * -1) 0;
  padding: calc(var(--spacer-padding-top) * 0.75) var(--container-gutter) 0;
  border-top: 1px solid rgba(16, 28, 128, 0.15);
}
.c-accordion__block:first-child {
  margin-top: var(--spacer-padding-bottom);
}

.c-accordion__media {
  width: 100%;
  max-width: 25rem;
}

@media (max-width: 767.98px) {
  .c-accordion__content img:not(:first-child) {
    margin-top: 2em;
  }
  .c-accordion__content img + * {
    margin-top: 1em !important;
  }
}
.c-accordion__content--with-image img:not(:first-child) {
  margin-top: 2em;
}
.c-accordion__content--with-image img + * {
  margin-top: 1em !important;
}
@media (min-width: 768px) {
  .c-accordion__content--without-image img {
    margin: 0;
    float: left;
    margin-left: -27.5%;
    transform: translateX(-50%);
  }
  .c-accordion__content--without-image img:not(:first-child) {
    margin-top: 2.5em;
  }
  .c-accordion__content--without-image img:first-child + * {
    margin-top: 0 !important;
  }
}
@media (min-width: 1180px) {
  .c-accordion__content--without-image img {
    margin-left: -20%;
  }
}

/* ==========================================================================
   #BLOCK
   ========================================================================== */
.c-block {
  --block-padding: 3rem 8rem 3rem 3rem;
  position: relative;
  min-height: 30rem;
  padding: var(--block-padding);
  background-color: var(--block-bg-color, #e4e7ff);
  cursor: pointer;
  user-select: none;
}
@media (min-width: 540px) {
  .c-block {
    --block-padding: 4rem 10rem 4rem 4rem;
  }
}
@media (min-width: 768px) {
  .c-block {
    --block-padding: 5rem 12rem 5rem 6rem;
  }
}

.c-block__list {
  display: grid;
  gap: 4rem;
  grid-template-columns: repeat(1, 1fr);
  width: 100%;
}
@media (min-width: 1180px) {
  .c-block__list {
    grid-template-columns: repeat(2, 1fr);
  }
}

.c-block__title {
  max-width: 17em;
  line-height: 1.11;
}
@media (min-width: 1180px) {
  .c-block__title {
    max-width: 11em;
  }
}

.c-block__button {
  --expander-bg-color: var(--block-active-bg-color);
  --expander-color: var(--block-bg-color);
  --expander-active-bg-color: var(--block-bg-color);
  --expander-active-color: var(--block-active-bg-color);
  position: absolute;
  bottom: 0.5em;
  right: 0.5em;
  z-index: 1;
}
@media (min-width: 768px) {
  .c-block__button {
    bottom: 1em;
    right: 1em;
  }
}

.c-block__overlay {
  position: absolute;
  inset: 0;
  padding: var(--block-padding);
  background-color: var(--block-active-bg-color, #000);
  color: #fff;
  overflow-y: auto;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
  opacity: 0;
  pointer-events: none;
  transition: opacity 300ms;
}
.is-expanded .c-block__overlay, .c-block--preview:focus .c-block__overlay {
  opacity: 1;
  pointer-events: all;
}

@media (min-width: 1024px) {
  .c-block__content {
    max-width: 92.5%;
  }
}
.c-block__content a[href]:not([class]):hover {
  color: currentColor;
}

/* ==========================================================================
   #BOTTOM
   ========================================================================== */
.c-bottom {
  margin-top: auto;
}

.c-bottom__columns {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 4rem;
}
@media (min-width: 768px) {
  .c-bottom__columns {
    flex-direction: row;
    gap: 8rem;
  }
}
@media (min-width: 1180px) {
  .c-bottom__columns {
    flex-wrap: nowrap;
    gap: 10;
  }
}

.c-bottom__column {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
@media (min-width: 768px) {
  .c-bottom__column:not(:first-child) {
    align-items: flex-start;
    text-align: left;
  }
}
.c-bottom__column:first-child {
  padding: calc(var(--container-gutter) * 2) var(--container-gutter);
  margin-top: calc(var(--spacer-padding-top) * -1);
  margin-left: calc(var(--container-gutter) * -1);
  margin-right: calc(var(--container-gutter) * -1);
  background-color: rgba(228, 231, 255, 0.2);
}
@media (min-width: 768px) {
  .c-bottom__column:first-child {
    width: 100%;
    padding: 0;
    margin: 0;
    background-color: transparent;
  }
}
@media (min-width: 1180px) {
  .c-bottom__column:first-child {
    width: 25%;
    align-items: flex-start;
    text-align: left;
  }
}

@media (max-width: 767.98px) {
  .c-bottom__title {
    font-size: 1.8rem;
  }
}
.c-bottom__title:not(:last-child) {
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  .c-bottom__title:not(:last-child) {
    margin-bottom: 3rem;
  }
}
@media (max-width: 1179.98px) {
  .c-bottom__title--featured {
    font-size: 2.8rem;
  }
}
@media (min-width: 768px) {
  .c-bottom__title--featured:not(:last-child) {
    margin-bottom: 2rem;
  }
}
@media (min-width: 1180px) {
  .c-bottom__title--featured:not(:last-child) {
    margin-bottom: 3rem;
  }
}

/* ==========================================================================
   #BUTTONS
   ========================================================================== */
.c-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: var(--button-min-width, 9.5em);
  min-height: var(--button-min-height, 4.5rem);
  padding: var(--button-padding, 0.25em 1em);
  background-color: var(--button-bg-color, transparent);
  color: var(--button-color, currentColor);
  border: 1px solid var(--button-border-color, currentColor);
  border-radius: 100vmin;
  cursor: pointer;
  font-size: var(--button-font-size, 2rem);
  font-weight: var(--button-font-weight, bold);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  transition: all 300ms ease-in-out;
}
.c-button:hover {
  --button-bg-color: #101c80;
  --button-border-color: transparent;
  --button-color: #fff;
}

/* Style variants
    ========================================================================== */
.c-button--primary {
  --button-bg-color: #ca4900;
  --button-border-color: transparent;
  --button-color: #fff;
}
.c-button--primary:hover, .c-button--primary:active, .c-button--primary:focus {
  --button-bg-color: #e26118;
}

/* ==========================================================================
   #CARD
   ========================================================================== */
.c-card__image {
  display: block;
  position: relative;
}

.c-card__overlay {
  position: absolute;
  left: 1em;
  right: 1em;
  bottom: 1.75em;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.c-card__tag {
  display: flex;
  min-width: 0;
  margin-right: 1em;
}

.c-card__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 2.25em;
  width: 2.25em;
  margin-top: -0.5em;
  margin-bottom: -0.5em;
  margin-left: auto;
  background-color: #c87f56;
  border-radius: 50%;
  color: #fff;
  font-size: 2.2rem;
}
@media (min-width: 1024px) {
  .c-card__icon--large {
    font-size: 2.6rem;
  }
}

.c-card__content:not(:first-child) {
  margin-top: calc(2rem * 1.25);
  --spacer-margin-top: calc(2rem * 1.25);
}

.c-card__title:not(:first-child),
.c-card__programs:not(:first-child) {
  margin-top: calc(2rem * 0.75);
  --spacer-margin-top: calc(2rem * 0.75);
}

/* ==========================================================================
   #CAROUSEL
   ========================================================================== */
.c-carousel::after {
  content: "flickity";
  display: none;
}
.c-carousel .flickity-viewport {
  overflow: visible;
  transition: height 0s;
}
.c-carousel .flickity-button {
  top: 23.5rem;
  color: #fff;
  filter: drop-shadow(0 0 0.5rem rgba(0, 0, 0, 0.5));
  transform: translateY(-50%);
}
.c-carousel .flickity-button.previous {
  right: auto;
  left: 0;
}
.c-carousel .flickity-button.next {
  left: auto;
  right: 0;
}
.c-carousel.o-grid::after {
  content: "";
}
@media (min-width: 768px) {
  .c-carousel.o-grid::after {
    content: "flickity";
  }
}
@media (min-width: 1180px) {
  .c-carousel.o-grid::after {
    content: "";
  }
}

.c-carousel__wrapper {
  display: flex;
  width: 9999em;
}
.c-carousel__wrapper > *:not(.flickity-enabled) {
  display: flex;
  width: 100%;
}

.c-carousel__item {
  width: calc(100% - var(--column-gutter));
  max-width: 28rem;
  margin-right: var(--column-gutter);
}
@media (min-width: 768px) {
  .c-carousel__item {
    max-width: 30rem;
    width: calc(50% - var(--column-gutter) * 0.5);
  }
}
@media (min-width: 1180px) {
  .c-carousel__item {
    width: calc(33.333% - var(--column-gutter) * 0.66667);
  }
}
.flickity-resize .c-carousel__item {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.flickity-resize .c-carousel__item > * {
  flex: 1;
}
@media (min-width: 1180px) {
  .c-carousel__item--separated {
    margin-right: calc(var(--column-gutter) * 2);
    width: calc(33.333% - var(--column-gutter) * 0.66667 * 2);
  }
}
.c-carousel__item--wide {
  max-width: 32rem;
}
@media (min-width: 768px) {
  .c-carousel__item--wide {
    max-width: 47rem;
    width: calc(50% - var(--column-gutter) * 0.5);
  }
}
@media (min-width: 1180px) {
  .c-carousel__item--wide {
    margin-right: calc(var(--column-gutter) * 2);
    width: calc(50% - var(--column-gutter) * 0.5 * 2);
  }
}

/* ==========================================================================
   #COLUMNS
   ========================================================================== */
.c-columns__lead {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
}
@media (min-width: 768px) {
  .c-columns__lead {
    background-color: var(--section-bg-color);
  }
  .c-columns__lead::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 100%;
    left: -50vw;
    width: calc(50vw + 100%);
    background-color: var(--section-bg-color);
  }
  .c-columns__lead > * {
    position: relative;
    z-index: 1;
  }
}
@media (min-width: 540px) {
  .c-columns__lead .c-lead {
    max-width: 48rem;
  }
}
@media (min-width: 768px) {
  .c-columns__lead .c-lead {
    max-width: 28rem;
  }
}
.c-columns__lead .c-lead__title {
  color: var(--section-title-color, #ca4900);
  margin-top: -0.125em;
}
@media (min-width: 768px) {
  .c-columns__lead .c-lead__copy {
    max-width: 26rem;
  }
}
.c-columns__lead--align-center .c-lead {
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
@media (min-width: 768px) {
  .c-columns__lead--align-center .c-lead {
    align-items: flex-start;
    margin-left: initial;
    margin-right: initial;
    text-align: left;
  }
}
.c-columns__lead--valign-top {
  justify-content: flex-start;
}
.c-columns__lead--valign-middle {
  justify-content: center;
}
.c-columns__lead--valign-bottom {
  justify-content: flex-end;
}

.c-columns__content--valign-top {
  align-self: flex-start;
}
.c-columns__content--valign-middle {
  align-self: center;
}
.c-columns__content--valign-bottom {
  align-self: flex-end;
}

/* ==========================================================================
   #COPY
   ========================================================================== */
.c-copy__button:not(:first-child) {
  margin-top: 1.75em;
}

/* Other variants
   ========================================================================== */
.c-copy--red h1 {
  color: #ca4900;
}
.c-copy--red h2 {
  color: #ca4900;
}
.c-copy--red h3 {
  color: #ca4900;
}
.c-copy--red h4 {
  color: #ca4900;
}
.c-copy--red h5 {
  color: #ca4900;
}
.c-copy--red h6 {
  color: #ca4900;
}

/* Alignment variants
   ========================================================================== */
.c-copy--align-left {
  margin-right: auto;
}

.c-copy--align-center {
  margin-left: auto;
  margin-right: auto;
}

.c-copy--align-right {
  margin-left: auto;
}

/* Text alignment variants
   ========================================================================== */
.c-copy--text-left,
.has-text-align-left {
  text-align: left;
}

.c-copy--text-center,
.has-text-align-center {
  text-align: center;
}

.c-copy--text-right,
.has-text-align-right {
  text-align: right;
}

/* Size variants
   ========================================================================== */
/* Style variants
   ========================================================================== */
.c-copy--bold {
  font-weight: bold;
}

/* ==========================================================================
   #DIAGRAM
   ========================================================================== */
.c-diagram {
  position: relative;
  width: 70vw;
  margin: 3em auto;
  font-size: 3.2rem;
  line-height: 0.75;
}
@media (min-width: 768px) {
  .c-diagram {
    margin-top: 1.75em;
    margin-bottom: 1.75em;
    font-size: 4.2rem;
  }
}
@media (min-width: 1024px) {
  .c-diagram {
    max-width: 72rem;
    font-size: 5.2rem;
  }
}

.c-diagram__image {
  width: 100%;
}

.c-diagram__value {
  position: absolute;
  z-index: 1;
  letter-spacing: 0.12em;
  text-align: center;
  white-space: nowrap;
}
.c-diagram__value:nth-child(1) {
  bottom: 100%;
}
.c-diagram__value:nth-child(2) {
  left: 100%;
  transform-origin: top left;
  transform: rotate(90deg) translate(-50%, -100%);
}
.c-diagram__value:nth-child(3) {
  top: 100%;
}
.c-diagram__value:nth-child(4) {
  right: 100%;
  transform-origin: top right;
  transform: rotate(-90deg) translate(50%, -100%);
}
.c-diagram__value:nth-child(1), .c-diagram__value:nth-child(3) {
  margin-bottom: 0.75em;
  margin-top: 0.75em;
  left: 50%;
  transform: translateX(-50%);
}
@media (min-width: 1024px) {
  .c-diagram__value:nth-child(1), .c-diagram__value:nth-child(3) {
    margin-bottom: 1em;
    margin-top: 1em;
  }
}
.c-diagram__value:nth-child(2), .c-diagram__value:nth-child(4) {
  margin-left: 5vw;
  margin-right: 5vw;
  top: 50%;
}
@media (min-width: 1024px) {
  .c-diagram__value:nth-child(2), .c-diagram__value:nth-child(4) {
    margin-left: 1em;
    margin-right: 1em;
  }
}
@media (min-width: 1180px) {
  .c-diagram__value:nth-child(2), .c-diagram__value:nth-child(4) {
    margin-left: 1.75em;
    margin-right: 1.75em;
  }
}

/* ==========================================================================
   #EXCERPT
   ========================================================================== */
.c-excerpt {
  display: flex;
  gap: 2rem;
  width: 100%;
}
@media (min-width: 1024px) {
  .c-excerpt {
    gap: 3rem;
  }
}

.c-excerpt__image {
  width: 37.5%;
  max-width: 18.5rem;
  flex-shrink: 0;
}

.c-excerpt__media {
  max-height: 15rem !important;
}

.c-excerpt__tag:not(:first-child) {
  margin-top: 0.75em;
}

/* ==========================================================================
   #EXPANDER
   ========================================================================== */
.c-expander {
  width: 1em;
  height: 1em;
  background-color: var(--expander-bg-color, transparent);
  box-shadow: 0 0.5rem 1.5rem 2px rgba(0, 0, 0, 0.15);
  color: var(--expander-color, currentColor);
  border-radius: 50%;
  font-size: 5rem;
}
.c-expander:hover, [data-expand]:hover .c-expander {
  opacity: 0.8;
}
.is-expanded .c-expander {
  background-color: var(--expander-active-bg-color, var(--expander-bg-color));
  color: var(--expander-active-color, var(--expander-color));
}

/* ==========================================================================
   #FILTERS
   ========================================================================== */
.c-filters__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: calc(var(--column-gutter) * 1);
}
@media (min-width: 768px) {
  .c-filters__wrapper {
    align-items: center;
    gap: calc(var(--column-gutter) * 2);
  }
}
@media (min-width: 1180px) {
  .c-filters__wrapper {
    flex-direction: row;
    justify-content: space-evenly;
  }
}

.c-filters__title {
  white-space: nowrap;
}
@media (min-width: 1180px) {
  .c-filters__title {
    width: 25%;
  }
}

.c-filters {
  position: relative;
  z-index: 10;
  width: 100%;
}

.c-filters__item {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  transition: opacity 300ms;
}
@media (min-width: 768px) {
  .c-filters__item {
    position: static;
  }
}

.c-filters__button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.625em 0;
  transition: opacity 300ms;
}
.c-filters__button::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  width: 100%;
  background-color: currentColor;
  opacity: 0.125;
  transition: opacity 300ms;
}
.c-filters__button .c-icon {
  opacity: 0.25;
  transition: opacity 300ms;
}
.c-filters__button:hover::after,
.c-filters__button:hover .c-icon {
  opacity: 0.5;
}
.is-open .c-filters__button::after,
.is-open .c-filters__button .c-icon, .c-filters__button--selected::after,
.c-filters__button--selected .c-icon {
  opacity: 1;
}
.has-dropdown-open .c-filters__button {
  opacity: 0.4;
}
.has-dropdown-open .is-open .c-filters__button {
  opacity: 1;
}

.c-filters__label {
  margin-right: 0.75em;
}

.c-filters__close {
  --plus-width: 1.25em;
  --plus-height: 0.125em;
  margin-right: 0.75em;
  transform: rotate(45deg);
}
.c-filters__close:hover {
  opacity: 0.5;
}

.c-filters__trigger {
  margin-left: auto;
  font-size: 1.5em;
}

.c-filters__clear {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-top: 2rem;
  padding: 0.65em 1em 0.55em;
  border: 1px solid currentColor;
  border-radius: 0.8rem;
  white-space: nowrap;
  transform: translateX(-50%);
}
@media (min-width: 1180px) {
  .c-filters__clear {
    left: 0;
    transform: none;
  }
}
.c-filters__clear:hover {
  background-color: rgba(255, 255, 255, 0.15);
}

.c-filters__dropdown {
  position: absolute;
  top: calc(100% + 1rem);
  left: 0;
  right: 0;
  z-index: 10;
  background-color: #fff9f9;
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.25);
  opacity: 0;
  pointer-events: none;
  transform: translate3d(0, 0.5rem, 0);
  transition-property: opacity, transform;
  transition-duration: 300ms;
  padding: 1.5em;
}
.is-open .c-filters__dropdown {
  opacity: 1;
  pointer-events: all;
  transform: translate3d(0, 0, 0);
}

/* ==========================================================================
   #FOOTER
   ========================================================================== */
.c-footer {
  padding-top: 3rem;
  padding-bottom: 3rem;
  border-top: 1px solid #e4e7ff;
}

.c-footer__copy {
  font-size: 0.875em;
  line-height: 1.5;
  text-align: center;
}
@media (min-width: 768px) {
  .c-footer__copy {
    font-size: 1em;
  }
}
.c-footer__copy:not(:first-child) {
  margin-top: 1.5em;
}
@media (min-width: 768px) {
  .c-footer__copy:not(:first-child) {
    margin-top: 0.5em;
  }
}
/* ==========================================================================
   #FORM
   ========================================================================== */
.c-form {
  max-width: 32rem;
  margin-left: auto;
  margin-right: auto;
}

.c-form__field:not(:first-child) {
  margin-top: 4rem;
}
@media (min-width: 1024px) {
  .c-form__field--separated:not(:first-child) {
    margin-top: 6rem;
  }
}

.c-form__input[name] {
  width: 100%;
  padding: 0.25em 0;
  border-bottom: 2px solid #b7bbe0;
  font-size: 1.8rem;
  text-align: left;
  transition: border-color 300ms;
}
.c-form__input[name]::placeholder {
  color: #8088d1;
}
.c-form__input[name]:focus, .c-form__input[name]:not(:placeholder-shown) {
  border-bottom-color: #101c80;
}

.c-form__button {
  width: 100%;
}

/* ==========================================================================
   #HAMBURGER
   ========================================================================== */
.c-hamburger {
  position: relative;
  width: var(--hamburger-width);
  height: var(--hamburger-width);
  cursor: pointer;
}
.c-hamburger:not(:last-child) {
  margin-right: calc(var(--hamburger-width) * 0.5);
}
@media (min-width: 768px) {
  .c-hamburger:not(:last-child) {
    margin-right: var(--hamburger-width);
  }
}
.c-hamburger:focus {
  outline: none;
}
.c-hamburger > * {
  position: relative;
  width: 100%;
  display: block;
  height: var(--hamburger-weight);
}
.c-hamburger > *::before, .c-hamburger > *::after {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background-color: var(--hamburger-color);
  border-radius: var(--hamburger-radius);
  transition: top var(--hamburger-duration) var(--hamburger-duration), bottom var(--hamburger-duration) var(--hamburger-duration), width var(--hamburger-duration) var(--hamburger-duration), transform var(--hamburger-duration) 0s, background-color var(--hamburger-duration) 0s;
}
.has-menu-open .c-hamburger > *::before, .has-menu-open .c-hamburger > *::after {
  transition: top var(--hamburger-duration) 0s, bottom var(--hamburger-duration) 0s, width var(--hamburger-duration) 0s, transform var(--hamburger-duration) var(--hamburger-duration), background-color var(--hamburger-duration) var(--hamburger-duration);
}
.c-hamburger > *::before {
  top: calc((var(--hamburger-height) * 0.5 - var(--hamburger-weight) * 0.5) * -1);
}
.has-menu-open .c-hamburger > *::before {
  top: 0;
  transform: rotate(45deg);
}
.c-hamburger > *::after {
  width: calc(var(--hamburger-width) * 0.666);
  bottom: calc((var(--hamburger-height) * 0.5 - var(--hamburger-weight) * 0.5) * -1);
}
.has-menu-open .c-hamburger > *::after {
  width: 100%;
  bottom: 0;
  transform: rotate(-45deg);
}

/* ==========================================================================
   #HEADER
   ========================================================================== */
.c-header {
  position: sticky;
  top: 0;
  z-index: 20;
  padding: 1.5rem var(--container-gutter);
  background-color: rgba(255, 255, 255, 0.975);
  display: flex;
  align-items: center;
}
@media (min-width: 768px) {
  .c-header {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}
.c-header a:not(.c-button):hover,
.c-header button:hover {
  color: #8088d1;
}
.has-menu-open .c-header {
  color: #b7bbe0;
  background-color: transparent;
  transition: color 0s 0.5s, background-color 300ms 0.5s;
}

.c-header__hamburger {
  flex-shrink: 0;
}

@media (min-width: 768px) {
  .c-header__logo:not(:last-child) {
    margin-right: 2em;
  }
}
.c-header__logo--custom {
  transition: opacity 300ms;
}
.has-menu-open .c-header__logo--custom {
  opacity: 0;
  pointer-events: none;
  transition-delay: 0.5s;
}

.c-header__nav {
  --button-font-size: 1.8rem;
  margin-left: auto;
  transition: opacity 300ms;
}
.has-menu-open .c-header__nav {
  opacity: 0;
  pointer-events: none;
  transition-delay: 0.5s;
}

/* ==========================================================================
   #HEADINGS
   ========================================================================== */
/* Size variants
   ========================================================================== */
/* Color variants
   ========================================================================== */
.c-heading--red {
  color: #ca4900;
}

/* Other variants
   ========================================================================== */
.c-heading--spacing-md {
  letter-spacing: 0.05em;
}

.c-heading--spacing-lg {
  letter-spacing: 0.12em;
}

/* ==========================================================================
   #ICON
   ========================================================================== */
.c-icon {
  display: block;
  width: 100%;
  height: 100%;
}
.c-icon * {
  fill: currentColor;
  stroke: none;
}

/* Size variants
   ========================================================================== */
.c-icon--sm {
  width: 0.75em;
  height: 0.75em;
}

.c-icon--md {
  width: 1em;
  height: 1em;
}

.c-icon--lg {
  width: 1.33em;
  height: 1.33em;
}

/* ==========================================================================
   #KNOB
   ========================================================================== */
.c-knob {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 4em;
  width: 4em;
  padding: 0.5em;
  margin: 0.5em;
  background-color: #ca4900;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  color: #fff;
  text-align: center;
}
.c-knob::before {
  content: "";
  position: absolute;
  inset: -0.4em;
  border: 1px solid #ca4900;
  border-radius: 50%;
  pointer-events: none;
}
.c-knob:hover, .c-knob:active, .c-knob:focus {
  background-color: #e26118;
}
.c-knob:hover::before, .c-knob:active::before, .c-knob:focus::before {
  border-color: #e26118;
}

/* Size variants
    ========================================================================== */
.c-knob--small {
  font-size: 2rem;
}
@media (min-width: 1024px) {
  .c-knob--small {
    font-size: 2rem;
  }
}

.c-knob--medium {
  font-size: 2.8rem;
}
@media (min-width: 1024px) {
  .c-knob--medium {
    font-size: 3rem;
  }
}

.c-knob--large {
  font-size: 3.4rem;
}
@media (min-width: 1024px) {
  .c-knob--large {
    font-size: 4.2rem;
  }
}

/* ==========================================================================
   #LABEL
   ========================================================================== */
.c-label--placeholder {
  display: block;
  visibility: hidden;
}
.c-label[href]:hover {
  opacity: 0.8;
}

.c-label__list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.c-label__list .c-label:not(:first-child) {
  margin-top: 0.5em;
}
.c-label__list--center {
  align-items: center;
}

/* ==========================================================================
   #LEAD
   ========================================================================== */
.c-lead {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.c-lead__label {
  padding: 0 0.25em;
}
.c-lead__label::after {
  content: "";
  display: block;
  width: 8rem;
  height: 1px;
  margin-left: auto;
  margin-right: auto;
  background-color: #e4e7ff;
}
.c-lead__label a:hover {
  color: #8088d1;
}

.c-lead__image {
  width: 17rem;
}
@media (min-width: 768px) {
  .c-lead__image {
    width: 11rem;
  }
}

.c-lead__title {
  max-width: 92rem;
}
.c-lead__title:not(:first-child) {
  margin-top: 2rem;
}

.c-lead__copy {
  max-width: 82rem;
}
.c-lead__copy--spacing-small:not(:first-child) {
  margin-top: 2rem;
}
.c-lead__copy--spacing-medium:not(:first-child) {
  margin-top: 3rem;
}
.c-lead__copy--spacing-large:not(:first-child) {
  margin-top: 4rem;
}
@media (min-width: 1024px) {
  .c-lead__copy--spacing-large:not(:first-child) {
    margin-top: 5rem;
  }
}
.c-lead__copy--narrow {
  max-width: 55rem;
}
.c-lead__copy em {
  display: none;
}
@media (min-width: 768px) {
  .c-lead__copy em {
    display: inline;
    font-style: normal;
  }
}
.c-lead__copy p {
  margin: 0;
}
@media (min-width: 0) {
  .c-lead__copy p:not(:first-child) {
    margin-top: calc(
          2rem * 0.25
        );
    --spacer-margin-top: calc(
      2rem * 0.25
    );
  }
}
@media (min-width: 768px) {
  .c-lead__copy p:not(:first-child) {
    margin-top: calc(
          2rem * 0.5
        );
    --spacer-margin-top: calc(
      2rem * 0.5
    );
  }
}
@media (min-width: 1024px) {
  .c-lead__copy p:not(:first-child) {
    margin-top: calc(
          2rem * 0.75
        );
    --spacer-margin-top: calc(
      2rem * 0.75
    );
  }
}
@media (min-width: 1180px) {
  .c-lead__copy p:not(:first-child) {
    margin-top: calc(
          2rem * 1
        );
    --spacer-margin-top: calc(
      2rem * 1
    );
  }
}

.c-lead__link:not(:first-child) {
  margin-top: 3rem;
}
@media (min-width: 1024px) {
  .c-lead__link--iconized:not(:first-child) {
    margin-top: 4.5rem;
  }
}

.c-lead__action:not(:first-child) {
  margin-top: 4rem;
}
@media (min-width: 1024px) {
  .c-lead__action:not(:first-child) {
    margin-top: 6rem;
  }
}

.c-lead__extras {
  display: contents;
  justify-content: inherit;
}
.c-lead__extras:not(:first-child) > * {
  margin-top: 3rem;
}

/* Alignment variants
   ========================================================================== */
.c-lead--align-left {
  align-items: flex-start;
  margin-right: auto;
  text-align: left;
}

.c-lead--align-center {
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.c-lead--align-right {
  align-items: flex-end;
  margin-left: auto;
  text-align: right;
}

.c-lead--align-center-until-xl {
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
@media (min-width: 1180px) {
  .c-lead--align-center-until-xl {
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 0;
    text-align: left;
  }
}

/* ==========================================================================
   #LINK
   ========================================================================== */
.c-link {
  display: inline-flex;
  align-items: center;
  color: currentColor;
  font-size: 1.8rem;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}
.c-link:hover {
  opacity: 0.8;
}

.c-link__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1em;
  margin-top: -0.1em;
  padding: 0.5em;
  background-color: var(--section-color);
  border-radius: 50%;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  color: var(--section-bg-color);
}
.c-link__icon svg {
  display: block;
  height: 1em;
  width: 1em;
}
.c-link__icon svg * {
  fill: currentColor;
  stroke: none;
}

/* ==========================================================================
   #LINKS
   ========================================================================== */
.c-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.c-links__item:not(:first-child) {
  margin-top: 1.5rem;
}

/* ==========================================================================
   #LIST
   ========================================================================== */
.c-list__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.c-list__copy {
  max-width: 22em;
}
@media (min-width: 768px) {
  .c-list__copy {
    max-width: 17.5em;
  }
}
@media (max-width: 1179.98px) {
  .c-list__copy.c-copy--size-md {
    font-size: 1.6rem;
  }
}
.c-list__copy:not(:first-child) {
  margin-top: 2.5rem;
}
.c-list__copy a {
  color: #ca4900;
  font-size: 0.75em;
  text-decoration: underline;
}
.c-list__copy a:hover {
  color: inherit;
}
.c-list__copy a::before {
  content: "\a";
  white-space: pre;
}

/* ==========================================================================
   #LOGO
   ========================================================================== */
.c-logo {
  display: block;
}
.c-logo[href]:hover {
  opacity: 0.75;
}

/* Type variants
    ========================================================================== */
.c-logo--text {
  font-weight: 500;
  white-space: nowrap;
}

.c-logo--image svg,
.c-logo--image img {
  display: block;
  width: 100%;
  height: 1.75em;
}
@media (min-width: 768px) {
  .c-logo--image svg,
.c-logo--image img {
    height: 2em;
  }
}
@media (min-width: 1024px) {
  .c-logo--image svg,
.c-logo--image img {
    height: 2.25em;
  }
}

/* Size variants
    ========================================================================== */
.c-logo--sm {
  font-size: 0.75em;
}

.c-logo--md {
  font-size: 1em;
}

.c-logo--lg {
  font-size: 1.25em;
}

/* ==========================================================================
   #MAP
   ========================================================================== */
.c-map {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.o-section--color-green .c-map {
  color: #e3e4b0;
}

.c-map__filters {
  width: 100%;
}

.c-map__image {
  user-select: none;
  pointer-events: none;
}

.c-map__content {
  position: relative;
  width: 100%;
  max-width: 101.5rem;
}
.c-map__content--small {
  max-width: 72rem;
}
.c-map__content:not(:first-child) {
  margin-top: var(--spacer-padding-bottom);
}

.c-map__stories {
  position: absolute;
  inset: 0;
}

.c-map__trigger {
  position: relative;
  display: block;
  width: 5.25%;
  color: #f4c125;
  user-select: none;
}
.c-map__trigger:hover {
  opacity: 0.8;
}
.c-map__trigger[style] {
  position: absolute;
  z-index: 1;
  transform: translate(-50%, -50%);
}

.c-map__plus {
  --plus-width: 1.25em;
  --plus-height: 0.25em;
  position: absolute;
  inset: 0;
  display: flex;
  align-items: flex-end;
}
.c-map__plus span {
  margin-left: -0.75em;
  margin-bottom: -0.25em;
}

/* ==========================================================================
   #MEDIA
   ========================================================================== */
.c-media {
  position: relative;
  display: block;
  overflow: hidden;
  background-color: #eff1ff;
  mask-image: radial-gradient(white, black);
}
@media (min-width: 1024px) {
  .c-media {
    max-height: 42vw;
  }
}
.c-media::before {
  content: "";
  display: block;
}
.c-media img {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 300ms;
}
[href]:hover > .c-media img, button:hover > .c-media img, .c-media[href]:hover img {
  transform: scale(1.05);
}

/* Aspect variants
    ========================================================================== */
.c-media--3-2::before {
  padding-bottom: 150%;
}

.c-media--1-1::before {
  padding-bottom: 100%;
}

.c-media--1-2::before {
  padding-bottom: 50%;
}

/* Border radius variants
    ========================================================================== */
.c-media--shape-rounded {
  border-radius: 0.8rem;
}

.c-media--shape-circle {
  border-radius: 50%;
}

.c-media--shape-pill {
  border-radius: 100vmin;
}

/* ==========================================================================
   #Menu
   ========================================================================== */
.c-menu {
  position: fixed;
  inset: 0;
  top: -100%;
  z-index: 15;
  padding: var(--container-gutter);
  padding-top: var(--header-outer-height);
  color: #fff;
  overflow: hidden;
  pointer-events: none;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
}
@media (min-width: 768px) {
  .c-menu {
    padding-left: calc(var(--container-gutter) + 2 * var(--hamburger-width));
  }
}
.c-menu::before {
  position: absolute;
  inset: 0;
  margin: auto;
  content: "";
  width: 100%;
  height: 0;
  background-color: #101c80;
  padding-bottom: 100%;
  border-radius: 100%;
  overflow: hidden;
  opacity: 0;
  transform: scale(0.04) translateY(9999px);
  transition: opacity 300ms;
}
.has-menu-open .c-menu {
  top: 0;
  pointer-events: all;
}
.has-menu-open .c-menu::before {
  opacity: 1;
  animation: menu-animation 0.8s ease-out forwards;
}

.c-menu__inner {
  --menu-offset: 2.5vh;
  position: relative;
  z-index: 1;
  margin-top: var(--menu-offset);
  height: calc(100% - var(--menu-offset));
  padding-left: 2rem;
  margin-left: -2rem;
  overflow-y: auto;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
}
@media (min-width: 768px) {
  .c-menu__inner {
    --menu-offset: 5vh;
  }
}
@media (min-width: 1024px) {
  .c-menu__inner {
    --menu-offset: 7.5vh;
  }
}

.c-menu__nav > ul > li {
  opacity: 0;
  transform: translate3d(-0.75em, 0, 0);
}
.has-menu-open .c-menu__nav > ul > li {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-property: transform, opacity;
  transition-duration: 300ms;
  transition-timing-function: ease-out;
}
.has-menu-open .c-menu__nav > ul > li:nth-child(1) {
  transition-delay: 0.75s;
}
.has-menu-open .c-menu__nav > ul > li:nth-child(2) {
  transition-delay: 0.85s;
}
.has-menu-open .c-menu__nav > ul > li:nth-child(3) {
  transition-delay: 0.95s;
}
.has-menu-open .c-menu__nav > ul > li:nth-child(4) {
  transition-delay: 1.05s;
}
.has-menu-open .c-menu__nav > ul > li:nth-child(5) {
  transition-delay: 1.15s;
}
.has-menu-open .c-menu__nav > ul > li:nth-child(6) {
  transition-delay: 1.25s;
}
.has-menu-open .c-menu__nav > ul > li:nth-child(7) {
  transition-delay: 1.35s;
}
.has-menu-open .c-menu__nav > ul > li:nth-child(8) {
  transition-delay: 1.45s;
}
.has-menu-open .c-menu__nav > ul > li:nth-child(9) {
  transition-delay: 1.55s;
}

.c-menu__socials {
  opacity: 0;
  transform: translate3d(-0.75em, 0, 0);
}
.has-menu-open .c-menu__socials {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition-property: transform, opacity;
  transition-duration: 300ms;
  transition-delay: 1s;
  transition-timing-function: ease-out;
}
.c-menu__socials:not(:first-child) {
  margin-top: 4rem;
}
@media (min-width: 768px) {
  .c-menu__socials:not(:first-child) {
    margin-top: 8rem;
  }
}

.c-menu__title {
  color: #b7bbe0;
}
.c-menu__title:not(:last-child) {
  margin-bottom: 1em;
}

/* ==========================================================================
   #NAV OVERLAY
   ========================================================================== */
.c-nav-overlay {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

.c-nav-overlay__item {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 2.7rem;
}
@media (min-width: 768px) {
  .c-nav-overlay__item {
    font-size: 3.2rem;
  }
}
@media (min-width: 1024px) {
  .c-nav-overlay__item {
    font-size: 3.5rem;
  }
}
.c-nav-overlay__item:not(:first-child) {
  margin-top: 1em;
}

.c-nav-overlay__link {
  --button-bg-color: #eeefd0;
  --button-border-color: #eeefd0;
  --button-color: #101c80;
  --button-padding: 0.25em 0.75em;
  --button-font-weight: normal;
  --button-min-width: 0;
  font-size: 1em;
}
.c-nav-overlay__link:hover {
  --button-bg-color: #eeefd0;
  --button-border-color: #eeefd0;
  --button-color: #101c80;
  opacity: 0.75;
}
.c-nav-overlay__link.c-button {
  margin-left: -1.5rem;
}
@media (min-width: 1024px) {
  .c-nav-overlay__link.c-button {
    margin-left: -2rem;
  }
}

.c-nav-overlay__toggle {
  position: relative;
  height: 2.4rem;
  width: 2.4rem;
  margin-left: 0.75em;
  color: #f2ccb7;
  font-size: 2.4rem;
  transform: rotate(-90deg);
  transition: transform 300ms;
}
.is-expanded .c-nav-overlay__toggle {
  transform: rotate(-90deg) scale(-1);
}
.c-nav-overlay__toggle:hover {
  opacity: 0.75;
}
.c-nav-overlay__toggle::before, .c-nav-overlay__toggle::after {
  content: "";
  position: absolute;
  left: 50%;
  width: 10%;
  height: 50%;
  background-color: currentColor;
}
.c-nav-overlay__toggle::before {
  top: 0;
  transform: skew(40deg, 0deg);
}
.c-nav-overlay__toggle::after {
  top: 50%;
  transform: skew(-40deg, 0deg);
}

.c-nav-overlay__subnav {
  display: none;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 0.66em;
}
.is-expanded .c-nav-overlay__subnav {
  display: flex;
}

.c-nav-overlay__subnav-item:not(:first-child) {
  margin-top: 1.25em;
}

.c-nav-overlay__subnav-link {
  color: #f2ccb7;
}
.c-nav-overlay__subnav-link[href]:hover, .c-nav-overlay__subnav-link[href]:focus {
  color: currentColor;
}

/* ==========================================================================
   #NAV PRIMARY
   ========================================================================== */
.c-nav-primary {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 0.5em 1.8em;
}

.c-nav-primary__item {
  font-size: 1.25em;
  font-weight: 500;
}

/* ==========================================================================
   #NAV SECONDARY
   ========================================================================== */
.c-nav-secondary {
  display: flex;
  flex-direction: column;
}

@media (min-width: 1024px) {
  .c-nav-secondary__item {
    font-size: 1.125em;
  }
}
.c-nav-secondary__item:not(:first-child) {
  margin-top: 0.75em;
}

/* ==========================================================================
   #NAV TERTIARY
   ========================================================================== */
.c-nav-tertiary {
  display: inline-flex;
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.c-nav-tertiary__item {
  padding-left: 0.5em;
  padding-right: 0.5em;
  border-left: 1px solid #8088d1;
  border-right: 1px solid #8088d1;
}
.c-nav-tertiary__item:not(:first-child) {
  margin-left: -1px;
}

/* ==========================================================================
   #NEWSLETTER
   ========================================================================== */
@media (max-width: 1179.98px) {
  .c-newsletter__copy {
    font-size: 1.6rem;
  }
}

.c-newsletter__form:not(:first-child) {
  margin-top: 2.5rem;
}

/* ==========================================================================
   #PAGINATION
   ========================================================================== */
.c-pagination {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  width: 100%;
}

.c-pagination__item {
  display: flex;
  align-items: center;
  gap: 1em;
}

.c-pagination__link {
  font-weight: bold;
}
@media (min-width: 768px) {
  .c-pagination__link {
    font-size: 1.25em;
  }
}

.c-pagination__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5em;
  height: 2.5em;
  background-color: #e4e7ff;
  border-radius: 50%;
  text-align: center;
}
@media (min-width: 768px) {
  .c-pagination__icon {
    font-size: 1.8rem;
  }
}
.c-pagination__icon .c-icon--chevron-left {
  transform: translateX(-0.1em);
}
.c-pagination__icon .c-icon--chevron-right {
  transform: translateX(0.1em);
}

/* ==========================================================================
   #POPUP
   ========================================================================== */
.c-popup {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 25;
  overflow: hidden;
  pointer-events: none;
  transform: translateX(-300%);
  transition: transform 0s 300ms;
}
.has-popup-open .c-popup {
  transform: translateX(0);
  pointer-events: all;
  transition: none;
}

.c-popup__close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  padding: 2rem;
  color: #8088d1;
  font-size: 2.4rem;
  text-transform: uppercase;
}
@media (min-width: 1024px) {
  .c-popup__close {
    padding: 3rem;
  }
}
.c-popup__close:hover {
  opacity: 0.75;
}
.has-iframe .c-popup__close {
  color: #fff;
}

.c-popup__content {
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(100% - (var(--container-gutter)));
  max-height: calc(100% - (var(--container-gutter)));
  max-width: 90rem;
  background-color: #fff;
  box-shadow: 0 0.5rem 4rem rgba(0, 0, 0, 0.15);
  border-radius: 0.8rem;
  opacity: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  margin-top: 5%;
  transform: translate3d(-50%, -50%, 0);
  transition: opacity 450ms, margin 450ms;
}
@media (min-width: 1024px) {
  .c-popup__content {
    width: calc(100% - var(--container-gutter) * 4);
  }
}
.has-popup-open .c-popup__content {
  opacity: 1;
  margin-top: 0;
}
.c-popup__content.has-iframe {
  max-width: none;
}

.c-popup__inner {
  padding: var(--container-gutter);
}
@media (min-width: 768px) {
  .c-popup__inner {
    padding-left: calc(var(--container-gutter) * 2);
    padding-right: calc(var(--container-gutter) * 2);
  }
}
@media screen and (min-width: 768px) and (min-height: 900px) {
  .c-popup__inner {
    padding-top: calc(var(--container-gutter) * 2);
    padding-bottom: calc(var(--container-gutter) * 2);
  }
}
.c-popup__inner img,
.c-popup__inner video,
.c-popup__inner iframe {
  display: block;
  width: 100%;
}
.has-iframe .c-popup__inner {
  padding: 0;
}

/* ==========================================================================
   #POST
   ========================================================================== */
.c-post__head {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.c-post__title:not(:first-child) {
  margin-top: calc(2rem * 0.75);
  --spacer-margin-top: calc(2rem * 0.75);
}

.c-post__excerpt {
  max-width: 56rem;
}
.c-post__excerpt:not(:first-child) {
  margin-top: calc(2rem * 0.75);
  --spacer-margin-top: calc(2rem * 0.75);
}

.c-post__labels:not(:first-child) {
  margin-top: calc(2rem * 1.25);
  --spacer-margin-top: calc(2rem * 1.25);
}

.c-post__date {
  color: #c87f56;
}
@media (min-width: 0) {
  .c-post__date:not(:first-child) {
    margin-top: calc(
          2rem * 0.625
        );
    --spacer-margin-top: calc(
      2rem * 0.625
    );
  }
}
@media (min-width: 768px) {
  .c-post__date:not(:first-child) {
    margin-top: calc(
          2rem * 1.25
        );
    --spacer-margin-top: calc(
      2rem * 1.25
    );
  }
}
@media (min-width: 1024px) {
  .c-post__date:not(:first-child) {
    margin-top: calc(
          2rem * 1.875
        );
    --spacer-margin-top: calc(
      2rem * 1.875
    );
  }
}
@media (min-width: 1180px) {
  .c-post__date:not(:first-child) {
    margin-top: calc(
          2rem * 2.5
        );
    --spacer-margin-top: calc(
      2rem * 2.5
    );
  }
}

@media (min-width: 0) {
  .c-post__copy:not(:first-child),
.c-post__content:not(:first-child) {
    margin-top: calc(
          2rem * 0.625
        );
    --spacer-margin-top: calc(
      2rem * 0.625
    );
  }
}
@media (min-width: 768px) {
  .c-post__copy:not(:first-child),
.c-post__content:not(:first-child) {
    margin-top: calc(
          2rem * 1.25
        );
    --spacer-margin-top: calc(
      2rem * 1.25
    );
  }
}
@media (min-width: 1024px) {
  .c-post__copy:not(:first-child),
.c-post__content:not(:first-child) {
    margin-top: calc(
          2rem * 1.875
        );
    --spacer-margin-top: calc(
      2rem * 1.875
    );
  }
}
@media (min-width: 1180px) {
  .c-post__copy:not(:first-child),
.c-post__content:not(:first-child) {
    margin-top: calc(
          2rem * 2.5
        );
    --spacer-margin-top: calc(
      2rem * 2.5
    );
  }
}

/* ==========================================================================
   #PROGRAM
   ========================================================================== */
.c-program {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.c-program__image {
  width: 100%;
}

@media (min-width: 0) {
  .c-program__content:not(:first-child) {
    margin-top: calc(
          2rem * 0.5
        );
    --spacer-margin-top: calc(
      2rem * 0.5
    );
  }
}
@media (min-width: 768px) {
  .c-program__content:not(:first-child) {
    margin-top: calc(
          2rem * 1
        );
    --spacer-margin-top: calc(
      2rem * 1
    );
  }
}
@media (min-width: 1024px) {
  .c-program__content:not(:first-child) {
    margin-top: calc(
          2rem * 1.5
        );
    --spacer-margin-top: calc(
      2rem * 1.5
    );
  }
}
@media (min-width: 1180px) {
  .c-program__content:not(:first-child) {
    margin-top: calc(
          2rem * 2
        );
    --spacer-margin-top: calc(
      2rem * 2
    );
  }
}

.c-program__link:hover {
  opacity: 0.8;
}
.c-program__link > span {
  display: inline;
  white-space: nowrap;
}

.c-program__button {
  margin-left: 2rem;
  color: #e4e7ff;
  vertical-align: 0.2em;
}

.c-program__copy {
  max-width: 42rem;
  font-size: 2rem;
  line-height: 1.5;
}
@media (min-width: 0) {
  .c-program__copy:not(:first-child) {
    margin-top: calc(
          2rem * 0.375
        );
    --spacer-margin-top: calc(
      2rem * 0.375
    );
  }
}
@media (min-width: 768px) {
  .c-program__copy:not(:first-child) {
    margin-top: calc(
          2rem * 0.75
        );
    --spacer-margin-top: calc(
      2rem * 0.75
    );
  }
}
@media (min-width: 1024px) {
  .c-program__copy:not(:first-child) {
    margin-top: calc(
          2rem * 1.125
        );
    --spacer-margin-top: calc(
      2rem * 1.125
    );
  }
}
@media (min-width: 1180px) {
  .c-program__copy:not(:first-child) {
    margin-top: calc(
          2rem * 1.5
        );
    --spacer-margin-top: calc(
      2rem * 1.5
    );
  }
}

/* ==========================================================================
   #SHAPES
   ========================================================================== */
.c-shape {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 13rem;
  height: 13rem;
}
.c-shape::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
  width: 100%;
  background-color: var(--shape-color, #eeefd0);
  transform: translate3d(-50%, -50%, 0);
}
.c-shape > * {
  position: relative;
  z-index: 1;
}

.c-shape__label {
  margin-bottom: -0.05em;
}

.c-shape__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 11.5rem;
  width: 11.5rem;
  background-color: var(--shape-icon-color, #fff9f9);
  border-radius: 50%;
  font-size: 8rem;
}
.c-shape__icon svg {
  height: 1em;
  width: 1em;
}
.c-shape__icon svg * {
  fill: currentColor;
  stroke: none;
}

/* Shape variants
   ========================================================================== */
.c-shape--diamond::before {
  height: 115%;
  width: 115%;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

.c-shape--tomb::before {
  border-radius: 5em 5em 0 0;
}

.c-shape--triangle::before {
  margin-top: -12.5%;
  width: 120%;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.c-shape--circle::before {
  clip-path: circle(50% at 50% 50%);
}

.c-shape--pill::before {
  height: 75%;
  width: 145%;
  border-radius: 100vmin;
}

/* ==========================================================================
   #SLIDER
   ========================================================================== */
.c-slider {
  padding-bottom: var(--spacer-padding-bottom);
}
.c-slider::after {
  content: "flickity";
  display: none;
}
@media (min-width: 1024px) {
  .c-slider {
    padding-bottom: 0;
  }
  .c-slider .flickity-page-dots {
    margin-top: calc(var(--spacer-padding-bottom) * 0.5);
  }
}

.c-slider__title {
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
  padding-bottom: 0.25em;
  background-color: #fff;
  text-align: center;
  transform: translateY(calc(var(--spacer-padding-bottom) * -1));
}

.c-slider__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--column-gutter);
  width: 100%;
}
@media (min-width: 768px) {
  .c-slider__item {
    flex-direction: row;
  }
}
@media (min-width: 1024px) {
  .c-slider__item {
    gap: calc(var(--column-gutter) * 2);
  }
}
@media (min-width: 1180px) {
  .c-slider__item {
    padding-left: 4.5vw;
    padding-right: 4.5vw;
  }
}

.c-slider__image {
  width: 100%;
  max-width: 25rem;
}
@media (min-width: 768px) {
  .c-slider__image {
    max-width: 28rem;
  }
}
@media (min-width: 1024px) {
  .c-slider__image {
    width: 43.6rem;
    max-width: 40%;
  }
}

.c-slider__content {
  align-self: center;
  flex: 1;
}
@media (min-width: 768px) {
  .c-slider__content {
    align-items: flex-start;
    margin-left: 0;
    text-align: left;
  }
}

/* ==========================================================================
   #SOCIALS
   ========================================================================== */
.c-socials {
  display: flex;
}

.c-socials__item:not(:first-child) {
  margin-left: 2em;
}

.c-socials__link {
  display: flex;
  align-items: center;
  padding: 0.5em;
  margin: -0.5em;
  color: #8088d1;
  font-size: 2.4rem;
  line-height: 1;
}
.c-socials__link * {
  fill: transparent;
  stroke: currentColor;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
  transition: all 300ms;
}
.c-socials__link:hover * {
  fill: currentColor;
  stroke-width: 1px;
}

/* ==========================================================================
   #TABS
   ========================================================================== */
.c-tabs {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}
@media (min-width: 768px) {
  .c-tabs {
    gap: 6rem;
  }
}

.c-tabs__list {
  display: flex;
  justify-content: space-around;
  gap: 2rem;
}
@media (min-width: 768px) {
  .c-tabs__list {
    justify-content: center;
    column-gap: 6rem;
  }
}
@media (min-width: 1024px) {
  .c-tabs__list {
    column-gap: 8rem;
  }
}

.c-tabs__link {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0.4;
  color: currentColor;
  font-size: 3rem;
  line-height: 1;
  text-align: center;
}
@media (min-width: 768px) {
  .c-tabs__link {
    font-size: 4rem;
  }
}
@media (min-width: 1024px) {
  .c-tabs__link {
    flex-direction: row;
    text-align: left;
  }
}
.c-tabs__link svg {
  flex-shrink: 0;
  height: 1em;
  width: 1em;
  margin-bottom: 0.25em;
}
@media (min-width: 1024px) {
  .c-tabs__link svg {
    margin-bottom: 0;
    margin-right: 0.25em;
  }
}
.c-tabs__link svg * {
  fill: currentColor;
  stroke: none;
}
.c-tabs__link.is-active {
  opacity: 1;
}

.c-tabs__panel {
  display: none;
}
.c-tabs__panel.is-active {
  display: block;
}

/* ==========================================================================
   #TAG
   ========================================================================== */
.c-tag {
  display: inline-flex;
  align-items: center;
  padding: 0.55em 0.8em 0.45em;
  background-color: var(--tag-bg-color, rgba(183, 187, 224, 0.2));
  border-radius: 0.8rem;
  color: var(--tag-color, #101c80);
}
@media (min-width: 768px) {
  .c-tag {
    padding: 0.65em 1em 0.55em;
  }
}
.c-tag[href]:hover {
  opacity: 0.8;
}

.c-tag__group {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: inherit;
  gap: 1rem;
}
@media (min-width: 768px) {
  .c-tag__group {
    row-gap: 1.5rem;
  }
}

/* Color variants
   ========================================================================== */
.c-tag--color-1 {
  --tag-bg-color: #f4f3ed;
  --tag-color: #1f0417;
}
.c-tag--color-1[href]:hover {
  --tag-bg-color: #fffded;
}

.c-tag--color-2 {
  --tag-bg-color: #e2e4f3;
  --tag-color: #1f0417;
}
.c-tag--color-2[href]:hover {
  --tag-bg-color: #d5d8f7;
}

.c-tag--color-3 {
  --tag-bg-color: #f2edf0;
  --tag-color: #1f0417;
}
.c-tag--color-3[href]:hover {
  --tag-bg-color: #f9edf5;
}

.c-tag--color-4 {
  --tag-bg-color: #faebe2;
  --tag-color: #1f0417;
}
.c-tag--color-4[href]:hover {
  --tag-bg-color: #ffe5d6;
}

.c-tag--color-5 {
  --tag-bg-color: #e4e7ff;
  --tag-color: #1f0417;
}
.c-tag--color-5[href]:hover {
  --tag-bg-color: #fefeff;
}

.c-tag--color-6 {
  --tag-bg-color: #fff9f9;
  --tag-color: #1f0417;
}
.c-tag--color-6[href]:hover {
  --tag-bg-color: #fffdfd;
}

.c-tag--color-7 {
  --tag-bg-color: #faf0e2;
  --tag-color: #1f0417;
}
.c-tag--color-7[href]:hover {
  --tag-bg-color: #ffeccf;
}

.c-tag__close {
  --plus-width: 1em;
  --plus-height: 0.125em;
  padding: 0.5em;
  margin: -0.5em;
  margin-left: 0;
  transform: translate(0.25em, -0.1em) rotate(45deg);
}
.c-tag__close:hover {
  color: #000;
}

/* Other variants
   ========================================================================== */
.c-tag--small {
  font-size: 1.2rem;
}

.c-tag--tight {
  padding: 0.45em 0.75em 0.35em;
}

.c-tag--selected,
.c-tag.is-selected {
  opacity: 0.5;
}

.c-tag.is-selected {
  transition: none;
}

/* ==========================================================================
   #TESTIMONIAL
   ========================================================================== */
.c-testimonial {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.c-testimonial__copy {
  max-width: 90rem;
  quotes: "“" "”" "‘" "’";
}
.c-testimonial__copy p:first-child::before {
  content: open-quote;
}
.c-testimonial__copy p:last-child::after {
  content: close-quote;
}

.c-testimonial__author {
  font-size: 2.2rem;
  font-weight: bold;
}
.c-testimonial__author::before {
  content: "–";
}
.c-testimonial__author:not(:first-child) {
  margin-top: 1em;
}

.c-testimonial__location {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #8088d1;
  font-size: 1.8rem;
}
.c-testimonial__location:not(:first-child) {
  margin-top: 2em;
}
.c-testimonial__location .c-icon {
  margin-bottom: 0.5em;
}

/* ==========================================================================
   #TILE
   ========================================================================== */
.c-tile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  max-width: 27rem;
  margin-left: auto;
  margin-right: auto;
}

.c-tile__partner--has-logo {
  height: 3.2rem;
}
@media (min-width: 768px) {
  .c-tile__partner--has-logo {
    height: 2.5rem;
  }
}
.c-tile__partner--has-logo img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center left;
}

.c-tile__title:not(:first-child) {
  margin-top: 2rem;
}
.c-tile__title:not(:last-child) {
  margin-bottom: auto;
}
.c-tile__title a {
  display: inline-block;
}
.c-tile__title a:hover {
  color: #8088d1;
}

.c-tile__tags:not(:first-child) {
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid rgba(183, 187, 224, 0.5);
}

/* ==========================================================================
   #TITLE
   ========================================================================== */
/* Size variants
   ========================================================================== */
/* Style variants
   ========================================================================== */
.c-title--light {
  font-weight: 300;
}

.c-title--normal {
  font-weight: normal;
}

.c-title--upper {
  text-transform: uppercase;
}

/* Other variants
   ========================================================================== */
.c-heading--separated {
  letter-spacing: 0.12em;
}

/* ==========================================================================
   #TOOLTIP
   ========================================================================== */
.c-tooltip {
  --tooltip-media-size: 6.8rem;
  position: relative;
  width: 40rem;
  max-width: calc(100vw - var(--container-gutter) * 2);
  padding: 2em;
  padding-top: 4.5em;
}

.c-tooltip__media {
  position: absolute;
  top: 1em;
  right: 1em;
  height: var(--tooltip-media-size);
  width: var(--tooltip-media-size);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.25);
  border-radius: 50%;
}

.c-tooltip__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.c-tooltip__title {
  padding-right: var(--tooltip-media-size);
}

.c-tooltip__copy:not(:first-child) {
  margin-top: 0.5em;
}

.c-tooltip__meta {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.75em;
}
.c-tooltip__meta:not(:first-child) {
  margin-top: 1em;
}

.c-tooltip__link {
  display: block;
  padding: 0.5em;
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 50%;
  font-size: 1.3rem;
}
.c-tooltip__link[href]:hover {
  opacity: 0.8;
}

.c-tooltip__close {
  position: absolute;
  top: 1em;
  left: 1em;
  transform: rotate(45deg);
}
.c-tooltip__close:hover {
  opacity: 0.8;
}

/* ==========================================================================
   #COMMON
   ========================================================================== */
.wp-block-buttons:not(:first-child),
.wp-block-code:not(:first-child),
.wp-block-table:not(:first-child),
.wp-block-latest-posts .wp-block-subhead:not(:first-child),
.wp-block-categories:not(:first-child),
.wp-block-verse:not(:first-child),
.wp-block-preformatted:not(:first-child),
.wp-block-columns:not(:first-child),
.wp-block-text-columns:not(:first-child) {
  margin-top: calc(1.25em * 1.25);
  --spacer-margin-top: calc(1.25em * 1.25);
}

.wp-block-image:not(:first-child),
.wp-block-cover:not(:first-child),
.wp-block-gallery:not(:first-child),
.wp-block-embed:not(:first-child),
.wp-block-quote:not(:first-child),
.wp-block-pullquote:not(:first-child) {
  margin-top: calc(1.25em * 2.5);
  --spacer-margin-top: calc(1.25em * 2.5);
}
.wp-block-image:not(:last-child),
.wp-block-cover:not(:last-child),
.wp-block-gallery:not(:last-child),
.wp-block-embed:not(:last-child),
.wp-block-quote:not(:last-child),
.wp-block-pullquote:not(:last-child) {
  margin-bottom: calc(1.25em * 2.5);
  --spacer-margin-bottom: calc(1.25em * 2.5);
}

/* ==========================================================================
   #Embed
   ========================================================================== */
.wp-block-embed .wp-block-embed__wrapper {
  position: relative;
}
.wp-block-embed .wp-block-embed__wrapper::before {
  content: "";
  display: block;
  padding-top: 56.25%;
}
.wp-block-embed .wp-block-embed__wrapper > * {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
}

/* ==========================================================================
   #Image
   ========================================================================== */
.wp-block-image.alignnone, .wp-block-image.aligncenter, .wp-block-image.alignright, .wp-block-image.alignleft {
  max-width: 100%;
  clear: both;
  margin: 0;
}
.wp-block-image.alignnone:not(:first-child), .wp-block-image.aligncenter:not(:first-child), .wp-block-image.alignright:not(:first-child), .wp-block-image.alignleft:not(:first-child) {
  margin-top: calc(1.25em * 1);
  --spacer-margin-top: calc(1.25em * 1);
}
.wp-block-image.alignright, .wp-block-image.alignleft {
  max-width: 45%;
  margin-top: 0;
  margin-bottom: 1em;
}
@media (min-width: 768px) {
  .wp-block-image.alignright, .wp-block-image.alignleft {
    margin-bottom: 2em;
  }
}
.wp-block-image.alignright {
  float: right;
  margin-left: 2em;
}
.wp-block-image.alignleft {
  float: left;
  margin-right: 2em;
}
@media (min-width: 768px) {
  .wp-block-image.alignleft {
    margin-right: 10%;
    margin-left: calc(var(--container-gutter) * -1);
  }
}
.wp-block-image.aligncenter {
  margin-left: auto;
  margin-right: auto;
}

/* ==========================================================================
   #Quote
   ========================================================================== */
.wp-block-quote {
  padding-left: 1.33em;
  border-left: 2px solid;
}

.wp-block-pullquote {
  text-align: center;
}

.wp-block-quote,
.wp-block-pullquote {
  color: #c87f56;
}
.wp-block-quote p,
.wp-block-pullquote p {
  font-size: 1.25em;
  line-height: 1.33;
}
.wp-block-quote cite,
.wp-block-pullquote cite {
  display: block;
  font-size: 1em;
}
@media (min-width: 0) {
  .wp-block-quote cite:not(:first-child),
.wp-block-pullquote cite:not(:first-child) {
    margin-top: calc(
          2rem * 0.25
        );
    --spacer-margin-top: calc(
      2rem * 0.25
    );
  }
}
@media (min-width: 768px) {
  .wp-block-quote cite:not(:first-child),
.wp-block-pullquote cite:not(:first-child) {
    margin-top: calc(
          2rem * 0.5
        );
    --spacer-margin-top: calc(
      2rem * 0.5
    );
  }
}
@media (min-width: 1024px) {
  .wp-block-quote cite:not(:first-child),
.wp-block-pullquote cite:not(:first-child) {
    margin-top: calc(
          2rem * 0.75
        );
    --spacer-margin-top: calc(
      2rem * 0.75
    );
  }
}
@media (min-width: 1180px) {
  .wp-block-quote cite:not(:first-child),
.wp-block-pullquote cite:not(:first-child) {
    margin-top: calc(
          2rem * 1
        );
    --spacer-margin-top: calc(
      2rem * 1
    );
  }
}
.wp-block-quote cite::before,
.wp-block-pullquote cite::before {
  content: "– ";
}

/* ==========================================================================
   #ALIGN
   ========================================================================== */
.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

.u-clear {
  clear: both;
}

.u-text-center {
  text-align: center;
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

/* ==========================================================================
   #CLAMP
   ========================================================================== */
.u-clamp {
  display: -webkit-box;
  /* stylelint-disable-line value-no-vendor-prefix */
  -webkit-box-orient: vertical;
  /* stylelint-disable-line property-no-vendor-prefix */
  overflow: hidden;
  text-overflow: ellipsis;
}
.u-clamp--1 {
  -webkit-line-clamp: 1;
}
.u-clamp--2 {
  -webkit-line-clamp: 2;
}
.u-clamp--3 {
  -webkit-line-clamp: 3;
}
.u-clamp--4 {
  -webkit-line-clamp: 4;
}
.u-clamp--5 {
  -webkit-line-clamp: 5;
}
.u-clamp--6 {
  -webkit-line-clamp: 6;
}

/* ==========================================================================
   #CLEARFIX
   ========================================================================== */
/**
 * Attach our clearfix mixin to a utility class.
 */
.u-clearfix::after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}

/* ==========================================================================
   #RESPONSIVE DISPLAY
   ========================================================================== */
.u-visible-xs-block {
  display: none !important;
}

.u-visible-xs-flex {
  display: none !important;
}

@media (min-width: 0) {
  .u-visible-xs-block {
    display: block !important;
  }

  .u-visible-xs-flex {
    display: flex !important;
  }

  .u-hidden-xs {
    display: none !important;
  }
}
.u-visible-sm-block {
  display: none !important;
}

.u-visible-sm-flex {
  display: none !important;
}

@media (min-width: 540px) {
  .u-visible-sm-block {
    display: block !important;
  }

  .u-visible-sm-flex {
    display: flex !important;
  }

  .u-hidden-sm {
    display: none !important;
  }
}
.u-visible-md-block {
  display: none !important;
}

.u-visible-md-flex {
  display: none !important;
}

@media (min-width: 768px) {
  .u-visible-md-block {
    display: block !important;
  }

  .u-visible-md-flex {
    display: flex !important;
  }

  .u-hidden-md {
    display: none !important;
  }
}
.u-visible-lg-block {
  display: none !important;
}

.u-visible-lg-flex {
  display: none !important;
}

@media (min-width: 1024px) {
  .u-visible-lg-block {
    display: block !important;
  }

  .u-visible-lg-flex {
    display: flex !important;
  }

  .u-hidden-lg {
    display: none !important;
  }
}
.u-visible-xl-block {
  display: none !important;
}

.u-visible-xl-flex {
  display: none !important;
}

@media (min-width: 1180px) {
  .u-visible-xl-block {
    display: block !important;
  }

  .u-visible-xl-flex {
    display: flex !important;
  }

  .u-hidden-xl {
    display: none !important;
  }
}
/* ==========================================================================
   #HIDE
   ========================================================================== */
/**
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.u-hidden-visually {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

/* ==========================================================================
   #LOADER
   ========================================================================== */
.u-loader {
  display: flex;
  margin-left: auto;
  margin-right: auto;
}
.u-loader.is-loading {
  position: relative;
  pointer-events: none;
}
.u-loader.is-loading span {
  color: transparent;
  transition: color 300ms;
}
.u-loader.is-loading::before {
  content: "";
  position: absolute;
  inset: 0;
  z-index: 25;
  background-image: url("../assets/images/loader-dark.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 50%;
}

/* ==========================================================================
   #NOTICE
   ========================================================================== */
.u-notice {
  text-align: center;
  opacity: 0.5;
}

/* ==========================================================================
   #PLAYER
   ========================================================================== */
.u-player {
  position: relative;
  overflow: hidden;
}
.u-player::before {
  content: "";
  display: block;
  padding-bottom: 56.25%;
}
.u-player > img,
.u-player > svg,
.u-player > video,
.u-player > iframe {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: calc(100% - 0px);
  width: calc(100% - 0px);
  object-fit: cover;
}