/* ==========================================================================
   #COPY
   ========================================================================== */

.c-copy {
  @extend %copy;
}

.c-copy__button {
  &:not(:first-child) {
    margin-top: 1.75em;
  }
}

/* Other variants
   ========================================================================== */

.c-copy--red {
  @for $i from 1 through 6 {
    h#{$i} {
      color: $color-red;
    }
  }
}

/* Alignment variants
   ========================================================================== */

.c-copy--align-left {
  margin-right: auto;
}

.c-copy--align-center {
  margin-left: auto;
  margin-right: auto;
}

.c-copy--align-right {
  margin-left: auto;
}

/* Text alignment variants
   ========================================================================== */

.c-copy--text-left,
.has-text-align-left {
  text-align: left;
}

.c-copy--text-center,
.has-text-align-center {
  text-align: center;
}

.c-copy--text-right,
.has-text-align-right {
  text-align: right;
}

/* Size variants
   ========================================================================== */

.c-copy--size-sm {
  @extend %style-copy-small;
}

.c-copy--size-lg {
  @extend %style-copy-large;
}

/* Style variants
   ========================================================================== */

.c-copy--bold {
  font-weight: bold;
}
