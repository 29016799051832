/* ==========================================================================
   #LABEL
   ========================================================================== */

.c-label {
  @extend %style-label;
  @extend %ellipsis;

  &--placeholder {
    display: block;
    visibility: hidden;
  }

  &[href]:hover {
    opacity: 0.8;
  }
}

.c-label__list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .c-label:not(:first-child) {
    margin-top: 0.5em;
  }

  &--center {
    align-items: center;
  }
}
