/* ==========================================================================
   #HEADINGS
   ========================================================================== */

.c-heading {
  @extend %heading;
}

/* Size variants
   ========================================================================== */

@for $i from 0 through 6 {
  .c-heading--h#{$i} {
    @extend %heading-h#{$i} !optional;
  }
}

/* Color variants
   ========================================================================== */

.c-heading--red {
  color: $color-red;
}

/* Other variants
   ========================================================================== */

.c-heading--spacing-md {
  letter-spacing: 0.05em;
}

.c-heading--spacing-lg {
  letter-spacing: 0.12em;
}
