/* ==========================================================================
   #SOCIALS
   ========================================================================== */

.c-socials {
  display: flex;
}

.c-socials__item {
  &:not(:first-child) {
    margin-left: 2em;
  }
}

.c-socials__link {
  display: flex;
  align-items: center;
  padding: 0.5em;
  margin: -0.5em;
  color: $color-purple;
  font-size: 2.4rem;
  line-height: 1;

  * {
    fill: transparent;
    stroke: currentColor;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
    transition: all $global-transition-speed;
  }

  &:hover * {
    fill: currentColor;
    stroke-width: 1px;
  }
}
