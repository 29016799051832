// ==========================================================================
// #DROPDOWN
// ==========================================================================

// Dropdown
@mixin dropdown($color: $color-white, $offset: 0) {
  position: absolute;
  top: calc(100% + #{$offset});
  left: 0;
  right: 0;
  z-index: $layer-dropdown;
  background-color: $color;
  box-shadow: 0 0.5rem 0.5rem rgba($color-black, 0.25);
  opacity: 0;
  pointer-events: none;
  transform: translate3d(0, #{$offset * 0.5}, 0);
  transition-property: opacity, transform;
  transition-duration: $global-transition-speed;
}

// Dropdown open
@mixin dropdown-open {
  opacity: 1;
  pointer-events: all;
  transform: translate3d(0, 0, 0);
}
