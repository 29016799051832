/* ==========================================================================
   #KNOB
   ========================================================================== */

.c-knob {
  @extend %style-knob;

  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 4em;
  width: 4em;
  padding: 0.5em;
  margin: 0.5em;
  background-color: $color-red;
  box-shadow: 0 0.5rem 1rem rgba($color-black, 0.2);
  border-radius: 50%;
  color: $color-white;
  text-align: center;

  &::before {
    content: '';
    position: absolute;
    inset: -0.4em;
    border: 1px solid $color-red;
    border-radius: 50%;
    pointer-events: none;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: $color-orange;

    &::before {
      border-color: $color-orange;
    }
  }
}

/* Size variants
    ========================================================================== */

.c-knob--small {
  font-size: 2rem;

  @include bp(lg) {
    font-size: 2rem;
  }
}

.c-knob--medium {
  font-size: 2.8rem;

  @include bp(lg) {
    font-size: 3rem;
  }
}

.c-knob--large {
  font-size: 3.4rem;

  @include bp(lg) {
    font-size: 4.2rem;
  }
}
