/* ==========================================================================
   #LINK
   ========================================================================== */

%link {
  color: $color-link;

  &[href]:hover {
    color: $color-hover;
  }
}

%link-block {
  [href] {
    display: block;

    &:hover {
      color: $color-link;
    }
  }
}

%link-transitions {
  transition-duration: $global-transition-speed;
  transition-property: color, background-color, border, outline, box-shadow,
    opacity, outline, transform;
}
