/* ==========================================================================
   #COMPONENT
   ========================================================================== */

.o-component {
  width: 100%;
}

/* Spacing variants
   ========================================================================== */

$component-paddings: (
  xs: 1,
  sm: 2,
  md: 3,
  lg: 4,
  xl: 5,
);

@each $name, $size in $component-paddings {
  .o-component--#{$name} {
    @include spacer($size, top, margin, 0.15);
  }
}
