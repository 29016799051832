/* ==========================================================================
   #GRID
   ========================================================================== */

.o-grid {
  display: grid;
  row-gap: calc(var(--column-gutter) * 2);
  column-gap: var(--column-gutter);

  @include bp(md) {
    row-gap: calc(var(--column-gutter) * 3);
  }

  // Alignment variants
  &--left {
    direction: ltr;
  }

  &--right {
    direction: rtl;
  }

  // Grid variants
  @each $breakpoint, $opts in $breakpoints {
    @for $i from 1 through 6 {
      @include bp($breakpoint) {
        &--#{$breakpoint}-#{$i} {
          grid-template-columns: repeat($i, 1fr);
        }
      }
    }
  }

  // Gap variants
  &--gap-sm {
    gap: calc(var(--column-gutter) * 1);

    @include bp(md) {
      gap: calc(var(--column-gutter) * 1.5);
    }
  }

  &--gap-lg {
    @include bp(md) {
      row-gap: calc(var(--column-gutter) * 3);
      column-gap: calc(var(--column-gutter) * 2);
    }

    @include bp(xl) {
      row-gap: calc(var(--column-gutter) * 4);
      column-gap: calc(var(--column-gutter) * 3);
    }
  }

  // V-alignment variants
  &--valign-middle {
    align-items: center;
  }
}

.o-grid__item {
  width: 100%;

  &--full {
    grid-column: 1 / -1;
    width: 100%;
  }

  // Grid variants
  @each $breakpoint, $opts in $breakpoints {
    @for $i from 1 through 6 {
      @include bp($breakpoint) {
        &--#{$breakpoint}-#{$i} {
          grid-column: span $i;
        }
      }
    }
  }
}
