/* ==========================================================================
   #SECTION
   ========================================================================== */

$section-paddings: (
  xs: 2,
  sm: 3,
  md: 4,
  lg: 5,
  xl: 6,
  xxl: 7,
  xxxl: 8,
);

$section-colors: (
  white: $color-white,
  shade: $color-shade,
  titan: $color-titan,
  powder: $color-powder,
  lt-green: $color-lt-green,
  green: $color-green,
  red: $color-red,
  navy: $color-navy,
);

.o-section {
  --section-bg-color: #{$color-white};
  --section-color: #{$color-navy};

  position: relative;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  background-color: var(--section-bg-color);
  color: var(--section-color);
}

.o-section__button-wrapper {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: $layer-base;
  max-width: $container-width;
  margin-right: auto;
  margin-left: auto;
}

.o-section__button {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);

  @include bp(md) {
    left: var(--container-gutter);
    transform: translateY(-50%);
  }

  @include bp(lg) {
    left: 10%;
  }
}

.o-section__link {
  @include bp(lg) {
    position: absolute;
    right: 0;
    bottom: calc(var(--spacer-padding-bottom) / 2);
    z-index: $layer-base;
    transform: translateY(50%);
  }

  .o-container {
    display: flex;
    justify-content: center;

    @include bp(lg) {
      justify-content: flex-end;
    }
  }
}

.o-section__content {
  display: flex;
  flex-direction: column;
  gap: calc(var(--column-gutter) * 1);
  max-width: var(--content-width);
  margin-left: auto;
  margin-right: auto;

  @include bp(md) {
    gap: calc(var(--column-gutter) * 1.5);
  }
}

/* Color variants
   ========================================================================== */

@each $name, $color in $section-colors {
  .o-section--color-#{'' + $name} {
    --section-bg-color: #{$color};

    &:not(.o-section--acf-image)
      + &:not(.o-section--separated):not(.o-section--acf-image) {
      padding-top: 0;
    }
  }
}

.o-section--color-green,
.o-section--color-red,
.o-section--color-navy {
  --section-color: #{$color-white};
  --section-title-color: #{$color-white};
}

.o-section--color-red {
  .c-button {
    --button-bg-color: #{$color-red};
    --button-border-color: #{$color-white};
    --button-color: #{$color-white};
  }
}

/* Spacing variants
   ========================================================================== */

@each $name, $size in $section-paddings {
  .o-section--p-#{$name} {
    @include spacer($size, top, padding, 0.15);
    @include spacer($size, bottom, padding, 0.15);
  }

  .o-section--pt-#{$name} {
    @include spacer($size, top, padding, 0.15);
  }

  .o-section--pb-#{$name} {
    @include spacer($size, bottom, padding, 0.15);
  }
}

/* Other variants
   ========================================================================== */

.o-section--has-button {
  @include bp-down(md) {
    padding-bottom: calc(var(--spacer-padding-bottom) * 1.75);
  }

  + .o-section {
    padding-top: calc(var(--spacer-padding-top) * 1.75);

    @include bp(lg) {
      padding-top: calc(var(--spacer-padding-top) * 2);
    }
  }
}

.o-section--separated {
  border-top: 1px solid $color-shade;
}

.o-section--nested {
  margin-left: calc((50vw - 50%) * -1);
  margin-right: calc((50vw - 50%) * -1);
}
