/* ==========================================================================
   #LINKS
   ========================================================================== */

.c-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.c-links__item {
  &:not(:first-child) {
    margin-top: 1.5rem;
  }
}

.c-links__link {
  @extend %style-link;
}
