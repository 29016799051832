/* ==========================================================================
   #EXCERPT
   ========================================================================== */

.c-excerpt {
  display: flex;
  gap: $global-spacer;
  width: 100%;

  @include bp(lg) {
    gap: $global-spacer * 1.5;
  }
}

.c-excerpt__image {
  width: 37.5%;
  max-width: 18.5rem;
  flex-shrink: 0;
}

.c-excerpt__media {
  max-height: 15rem !important;
}

.c-excerpt__title {
  @extend %link-block;
}

.c-excerpt__tag {
  &:not(:first-child) {
    margin-top: 0.75em;
  }
}
