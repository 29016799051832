/* ==========================================================================
   #BOTTOM
   ========================================================================== */

.c-bottom {
  margin-top: auto;
}

.c-bottom__columns {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 4rem;

  @include bp(md) {
    flex-direction: row;
    gap: 8rem;
  }

  @include bp(xl) {
    flex-wrap: nowrap;
    gap: 10;
  }
}

.c-bottom__column {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &:not(:first-child) {
    @include bp(md) {
      align-items: flex-start;
      text-align: left;
    }
  }

  &:first-child {
    padding: calc(var(--container-gutter) * 2) var(--container-gutter);
    margin-top: calc(var(--spacer-padding-top) * -1);
    margin-left: calc(var(--container-gutter) * -1);
    margin-right: calc(var(--container-gutter) * -1);
    background-color: rgba($color-shade, 0.2);

    @include bp(md) {
      width: 100%;
      padding: 0;
      margin: 0;
      background-color: transparent;
    }

    @include bp(xl) {
      width: 25%;
      align-items: flex-start;
      text-align: left;
    }
  }
}

.c-bottom__title {
  @include bp-down(md) {
    font-size: 1.8rem;
  }

  &:not(:last-child) {
    margin-bottom: 2rem;

    @include bp(md) {
      margin-bottom: 3rem;
    }
  }

  &--featured {
    @include bp-down(xl) {
      font-size: 2.8rem;
    }

    &:not(:last-child) {
      @include bp(md) {
        margin-bottom: 2rem;
      }

      @include bp(xl) {
        margin-bottom: 3rem;
      }
    }
  }
}
