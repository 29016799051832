/* ==========================================================================
   #FONT-FACE
   ========================================================================== */

/**
 * Add font-display: swap to font-face blocks here
 * https://www.zachleat.com/web/comprehensive-webfonts/#font-display
 */

@font-face {
  font-family: 'League Gothic';
  src: url('../assets/fonts/leaguegothic-regular-webfont.woff2') format('woff2'),
    url('../assets/fonts/leaguegothic-regular-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Martin;
  src: url('../assets/fonts/MARTIN-Regular.woff2') format('woff2'),
    url('../assets/fonts/MARTIN-Regular.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Avenir;
  src: url('../assets/fonts/AvenirLTProLight/font.woff2') format('woff2'),
    url('../assets/fonts/AvenirLTProLight/font.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Avenir;
  src: url('../assets/fonts/AvenirLTProRoman/font.woff2') format('woff2'),
    url('../assets/fonts/AvenirLTProRoman/font.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Avenir;
  src: url('../assets/fonts/AvenirLTProMedium/font.woff2') format('woff2'),
    url('../assets/fonts/AvenirLTProMedium/font.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Avenir;
  src: url('../assets/fonts/AvenirLTProHeavy/font.woff2') format('woff2'),
    url('../assets/fonts/AvenirLTProHeavy/font.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
