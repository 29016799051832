/* ==========================================================================
   #CAROUSEL
   ========================================================================== */

.c-carousel {
  // width: 100%;

  &::after {
    content: 'flickity';
    display: none;
  }

  .flickity-viewport {
    overflow: visible;
    transition: height 0s;
  }

  .flickity-button {
    top: 23.5rem;
    color: $color-white;
    filter: drop-shadow(0 0 0.5rem rgba($color-black, 0.5));
    transform: translateY(-50%);

    &.previous {
      right: auto;
      left: 0;
    }

    &.next {
      left: auto;
      right: 0;
    }
  }

  &.o-grid {
    &::after {
      content: '';

      @include bp(md) {
        content: 'flickity';
      }

      @include bp(xl) {
        content: '';
      }
    }
  }
}

.c-carousel__wrapper {
  display: flex;
  width: 9999em;

  > *:not(.flickity-enabled) {
    display: flex;
    width: 100%;
  }
}

.c-carousel__item {
  width: calc(100% - var(--column-gutter));
  max-width: 28rem;
  margin-right: var(--column-gutter);

  @include bp(md) {
    max-width: 30rem;
    width: calc(50% - (var(--column-gutter) * 0.5));
  }

  @include bp(xl) {
    width: calc(33.333% - (var(--column-gutter) * 0.66667));
  }

  .flickity-resize & {
    display: flex;
    flex-direction: column;
    min-height: 100%;

    > * {
      flex: 1;
    }
  }

  &--separated {
    @include bp(xl) {
      margin-right: calc(var(--column-gutter) * 2);
      width: calc(33.333% - (var(--column-gutter) * 0.66667 * 2));
    }
  }

  &--wide {
    max-width: 32rem;

    @include bp(md) {
      max-width: 47rem;
      width: calc(50% - (var(--column-gutter) * 0.5));
    }

    @include bp(xl) {
      margin-right: calc(var(--column-gutter) * 2);
      width: calc(50% - (var(--column-gutter) * 0.5 * 2));
    }
  }
}
