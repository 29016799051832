/* ==========================================================================
   #NEWSLETTER
   ========================================================================== */

.c-newsletter__copy {
  @extend %style-copy;

  @include bp-down(xl) {
    font-size: 1.6rem;
  }
}

.c-newsletter__form {
  &:not(:first-child) {
    margin-top: 2.5rem;
  }
}
