/* ==========================================================================
   #TABS
   ========================================================================== */

.c-tabs {
  display: flex;
  flex-direction: column;
  gap: $global-spacer * 2;

  @include bp(md) {
    gap: $global-spacer * 3;
  }
}

.c-tabs__list {
  display: flex;
  justify-content: space-around;
  gap: $global-spacer;

  @include bp(md) {
    justify-content: center;
    column-gap: $global-spacer * 3;
  }

  @include bp(lg) {
    column-gap: $global-spacer * 4;
  }
}

.c-tabs__link {
  @extend %heading;

  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0.4;
  color: currentColor;
  font-size: 3rem;
  line-height: 1;
  text-align: center;

  @include bp(md) {
    font-size: 4rem;
  }

  @include bp(lg) {
    flex-direction: row;
    text-align: left;
  }

  svg {
    flex-shrink: 0;
    height: 1em;
    width: 1em;
    margin-bottom: 0.25em;

    @include bp(lg) {
      margin-bottom: 0;
      margin-right: 0.25em;
    }

    * {
      fill: currentColor;
      stroke: none;
    }
  }

  &.is-active {
    opacity: 1;
  }
}

.c-tabs__panel {
  display: none;

  &.is-active {
    display: block;
  }
}
