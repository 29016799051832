/* ==========================================================================
   #TOOLTIP
   ========================================================================== */

.c-tooltip {
  --tooltip-media-size: 6.8rem;

  position: relative;
  width: 40rem;
  max-width: calc(100vw - (var(--container-gutter) * 2));
  padding: 2em;
  padding-top: 4.5em;
}

.c-tooltip__media {
  position: absolute;
  top: 1em;
  right: 1em;
  height: var(--tooltip-media-size);
  width: var(--tooltip-media-size);
  box-shadow: 0 0.5rem 1rem rgba($color-black, 0.25);
  border-radius: 50%;
}

.c-tooltip__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.c-tooltip__title {
  padding-right: var(--tooltip-media-size);
}

.c-tooltip__copy {
  &:not(:first-child) {
    margin-top: 0.5em;
  }
}

.c-tooltip__meta {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.75em;

  &:not(:first-child) {
    margin-top: 1em;
  }
}

.c-tooltip__link {
  display: block;
  padding: 0.5em;
  background-color: rgba($color-white, 0.25);
  border-radius: 50%;
  font-size: 1.3rem;

  &[href]:hover {
    opacity: 0.8;
  }
}

.c-tooltip__close {
  @extend %plus-sign;

  position: absolute;
  top: 1em;
  left: 1em;
  transform: rotate(45deg);

  &:hover {
    opacity: 0.8;
  }
}
