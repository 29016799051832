/* ==========================================================================
   #LOADER
   ========================================================================== */

.u-loader {
  display: flex;
  margin-left: auto;
  margin-right: auto;

  &.is-loading {
    position: relative;
    pointer-events: none;

    span {
      color: transparent;
      transition: color $global-transition-speed;
    }

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      z-index: $layer-popup;
      background-image: url('../assets/images/loader-dark.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: auto 50%;
    }
  }
}
