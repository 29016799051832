/* ==========================================================================
   #CONTAINER
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

.o-container {
  @extend %container;

  &--fluid {
    max-width: none;
  }
}
