/* ==========================================================================
   #SHAPES
   ========================================================================== */

.c-shape {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 13rem;
  height: 13rem;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    height: 100%;
    width: 100%;
    background-color: var(--shape-color, #{$color-lt-green});
    transform: translate3d(-50%, -50%, 0);
  }

  > * {
    position: relative;
    z-index: $layer-base;
  }
}

.c-shape__label {
  @extend %style-stat;

  margin-bottom: -0.05em;
}

.c-shape__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 11.5rem;
  width: 11.5rem;
  background-color: var(--shape-icon-color, #{$color-powder});
  border-radius: 50%;
  font-size: 8rem;

  svg {
    height: 1em;
    width: 1em;

    * {
      fill: currentColor;
      stroke: none;
    }
  }
}

/* Shape variants
   ========================================================================== */

.c-shape--diamond::before {
  height: 115%;
  width: 115%;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

.c-shape--tomb::before {
  border-radius: 5em 5em 0 0;
}

.c-shape--triangle::before {
  margin-top: -12.5%;
  width: 120%;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.c-shape--circle::before {
  clip-path: circle(50% at 50% 50%);
}

.c-shape--pill::before {
  height: 75%;
  width: 145%;
  border-radius: 100vmin;
}
