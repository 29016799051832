/* ==========================================================================
   #CAPTION
   ========================================================================== */

%caption {
  @extend %style-copy;

  color: $color-green;
  text-align: left;
}
