// ==========================================================================
// #GLOBAL
// ==========================================================================
// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.

$global-radius: 0.8rem !default;
$global-spacer: 2rem !default;
$global-transition-speed: 300ms !default;
$global-transition: all $global-transition-speed ease-in-out !default;

/* Fonts
   ========================================================================== */

$font-sans-serif: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI',
  'Roboto', 'Helvetica Neue', 'Arial', sans-serif !default;

$font-avenir: avenir, sans-serif !default;
$font-martin: martin, /*iOS*/ 'AvenirNextCondensed-Bold',
  'Futura-CondensedExtraBold', /*MacOSX*/ helveticaneue-condensedbold,
  /*Ubuntu*/ 'Ubuntu Condensed', 'Liberation Sans Narrow',
  /*Windows*/ 'Franklin Gothic Demi Cond', 'Arial Narrow',
  /*Android*/ sans-serif-condensed, /*Fallback*/ arial, 'Trebuchet MS',
  'Lucida Grande', tahoma, verdana, sans-serif !default;
$font-league: 'League Gothic', sans-serif !default;

/* Breakpoints
   ========================================================================== */

$breakpoints: (
  xs: 0,
  sm: 540px,
  md: 768px,
  lg: 1024px,
  xl: 1180px,
);

/* Responsive display values
   ========================================================================== */

$display-values: (block, flex);

/* Layers
   ========================================================================== */

$layer-negative: -1 !default;
$layer-neutral: 0 !default;
$layer-base: 1 !default;
$layer-utils: 5 !default;
$layer-dropdown: 10 !default;
$layer-menu: 15 !default;
$layer-header: 20 !default;
$layer-popup: 25 !default;

/* Colors
   ========================================================================== */

// Colors shouldn't be used directly but reassigned
$color-white: #fff;
$color-black: #000;

$color-snow: #fff9f9;
$color-titan: #eff1ff;
$color-shade: #e4e7ff;
$color-powder: #fff9f9;
$color-purple: #8088d1;
$color-brown: #1f0417;
$color-navy: #101c80;
$color-red: #ca4900;
$color-tusk: #e3e4b0;
$color-green: #3f5e4d;
$color-tan: #c87f56;
$color-yellow: #f4c125;
$color-orange: #e26118;

$color-tags: (
  #f4f3ed: #fffded,
  #e2e4f3: #d5d8f7,
  #f2edf0: #f9edf5,
  #faebe2: #ffe5d6,
  #e4e7ff: #fefeff,
  #fff9f9: #fffdfd,
  #faf0e2: #ffeccf,
);

// Light colors
$color-lt-blue: #b7bbe0;
$color-lt-tan: #f2ccb7;
$color-lt-green: #eeefd0;
$color-lt-purple: #ded2da;
$color-lt-orange: #fff1c7;

// Theme colors
$color-theme-navy: #101c80;

// Links
$color-link: $color-purple;
$color-hover: $color-navy;

// Text
$color-bg: $color-white;
$color-text: $color-navy;

/* Typography
   ========================================================================== */

$font-family: $font-avenir;
$font-size: 1.6em;
$font-weight: normal;
$font-line-height: 1.25;

/* Layout
   ========================================================================== */

$container-width: 124rem;

/* GLOBAL CSS VARIABLES
   ========================================================================== */

:root {
  --column-gutter: 2.4rem;
  --container-gutter: 3.2rem;
  --content-width: 95rem;
  --header-padding: 1.5rem;
  --header-height: 4.5rem;
  --header-outer-height: calc(
    var(--header-height) + (2 * var(--header-padding))
  );

  // Hamburger
  --hamburger-width: 3.5rem;
  --hamburger-height: 1rem;
  --hamburger-weight: 2px;
  --hamburger-color: currentColor;
  --hamburger-duration: 0.25s;
  --hamburger-radius: 3px;

  @include bp(md) {
    --header-padding: 2rem;
  }

  @include bp(lg) {
    --container-gutter: 5rem;
  }
}
