/* ==========================================================================
   #TITLE
   ========================================================================== */

.c-title {
  @extend %title;
}

/* Size variants
   ========================================================================== */

@for $i from 0 through 6 {
  .c-title--h#{$i} {
    @extend %title-h#{$i} !optional;
  }
}

/* Style variants
   ========================================================================== */

.c-title--light {
  font-weight: 300;
}

.c-title--normal {
  font-weight: normal;
}

.c-title--upper {
  text-transform: uppercase;
}

/* Other variants
   ========================================================================== */

.c-heading--separated {
  letter-spacing: 0.12em;
}
