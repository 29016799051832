/* ==========================================================================
   #NAV SECONDARY
   ========================================================================== */

.c-nav-secondary {
  display: flex;
  flex-direction: column;
}

.c-nav-secondary__item {
  @include bp(lg) {
    font-size: 1.125em;
  }

  &:not(:first-child) {
    margin-top: 0.75em;
  }
}

.c-nav-secondary__link {
  @extend %link;
}
