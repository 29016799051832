/*
** Vendor - Flickity
** ----------------------------------------------------------------------------- */

/* prevent page scrolling when flickity is fullscreen */
.is-flickity-fullscreen {
  overflow: hidden;
}

.flickity-enabled {
  position: relative;

  &:focus {
    outline: none;
  }

  &.is-draggable {
    user-select: none;

    .flickity-viewport {
      cursor: grab;

      &.is-pointer-down {
        cursor: grabbing;
      }
    }
  }

  &.is-fade .flickity-slider {
    > * {
      pointer-events: none;
    }

    > .is-selected {
      pointer-events: auto;
    }
  }
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
  transition: height 0.25s;

  .is-fullscreen & {
    transition: none;
  }
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

.flickity-page-dots {
  position: absolute;
  top: 100%;
  left: 50%;
  display: flex;
  justify-content: center;
  gap: var(--column-gutter);
  transform: translate(-50%, -50%);

  .dot {
    width: 1em;
    height: 1em;
    background: $color-red;
    border-radius: 50%;
    cursor: pointer;
    opacity: 0.5;
    transition-property: opacity;
    transition-duration: $global-transition-speed;

    &.is-selected {
      opacity: 1;
    }

    &:hover {
      opacity: 0.75;
    }
  }
}

.flickity-button {
  position: absolute;
  top: 100%;
  display: none;
  color: $color-red;
  opacity: 0.75;
  font-size: 6rem;

  @include bp(xl) {
    display: block;
    top: 50%;
    font-size: 12rem;
  }

  &.previous {
    right: 100%;

    @include bp(lg) {
      transform: translate(33.3%, -50%);
    }
  }

  &.next {
    left: 100%;

    @include bp(lg) {
      transform: translate(-33.3%, -50%);
    }
  }

  svg {
    display: block;
    height: 1em;
    width: 1em;
    fill: currentColor;
    stroke: none;
  }

  &:not([disabled]):hover {
    opacity: 1;
  }

  &[disabled] {
    opacity: 0;
    pointer-events: none;
  }
}
