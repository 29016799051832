/* ==========================================================================
   #TITLE
   ========================================================================== */

%title {
  font-weight: bold;
}

%title-h0 {
  font-size: 3.4rem;
  line-height: 1.15;

  @include bp(sm) {
    font-size: 3.6rem;
    line-height: 1.11;
  }

  @include bp(lg) {
    font-size: 4rem;
  }

  @include bp(xl) {
    font-size: 4.5rem;
  }
}

%title-h1 {
  font-size: 3.2rem;
  line-height: 1.225;

  @include bp(md) {
    font-size: 3.6rem;
  }

  @include bp(lg) {
    font-size: 4rem;
  }
}

%title-h2 {
  font-size: 2.7rem;
  line-height: 1.2;

  @include bp(md) {
    font-size: 3rem;
  }
}

%title-h3 {
  font-size: 2.4rem;
  line-height: $font-line-height;

  @include bp(md) {
    font-size: 2.7rem;
  }
}

%title-h4 {
  font-size: 2rem;
  line-height: $font-line-height;

  @include bp(md) {
    font-size: 2.4rem;
  }
}

%title-h5 {
  font-size: 1.8rem;
  line-height: $font-line-height;

  @include bp(md) {
    font-size: 2rem;
  }
}

%title-h6 {
  font-size: 1.8rem;
  line-height: $font-line-height;
}
