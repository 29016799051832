/* ==========================================================================
   #NAV PRIMARY
   ========================================================================== */

.c-nav-primary {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 0.5em 1.8em;
}

.c-nav-primary__item {
  font-size: 1.25em;
  font-weight: 500;
}
