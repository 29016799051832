/* ==========================================================================
   #LIST
   ========================================================================== */

.c-list__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.c-list__copy {
  max-width: 22em;

  @include bp(md) {
    max-width: 17.5em;
  }

  &.c-copy--size-md {
    @include bp-down(xl) {
      font-size: 1.6rem;
    }
  }

  &:not(:first-child) {
    margin-top: 2.5rem;
  }

  a {
    color: $color-red;
    font-size: 0.75em;
    text-decoration: underline;

    &:hover {
      color: inherit;
    }

    &::before {
      content: '\A';
      white-space: pre;
    }
  }
}
