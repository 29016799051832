/* ==========================================================================
   #PAGE
   ========================================================================== */

html {
  height: 100%;
  width: 100%;
  font-size: 62.5%;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-y: scroll;
  overflow-x: hidden;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}
