/* ==========================================================================
   #CLAMP
   ========================================================================== */

.u-clamp {
  display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
  -webkit-box-orient: vertical; /* stylelint-disable-line property-no-vendor-prefix */
  overflow: hidden;
  text-overflow: ellipsis;

  // Line clamp variants
  @for $i from 1 through 6 {
    &--#{$i} {
      -webkit-line-clamp: $i;
    }
  }
}
