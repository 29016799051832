/* ==========================================================================
   #BUTTONS
   ========================================================================== */

.c-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: var(--button-min-width, 9.5em);
  min-height: var(--button-min-height, 4.5rem);
  padding: var(--button-padding, 0.25em 1em);
  background-color: var(--button-bg-color, transparent);
  color: var(--button-color, currentColor);
  border: 1px solid var(--button-border-color, currentColor);
  border-radius: 100vmin;
  cursor: pointer;
  font-size: var(--button-font-size, 2rem);
  font-weight: var(--button-font-weight, bold);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  transition: $global-transition;

  &:hover {
    --button-bg-color: #{$color-navy};
    --button-border-color: transparent;
    --button-color: #{$color-white};
  }
}

/* Style variants
    ========================================================================== */

.c-button--primary {
  --button-bg-color: #{$color-red};
  --button-border-color: transparent;
  --button-color: #{$color-white};

  &:hover,
  &:active,
  &:focus {
    --button-bg-color: #{$color-orange};
  }
}
