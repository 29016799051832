/* ==========================================================================
   #STYLE
   ========================================================================== */

%style-copy {
  font-size: 1.8rem;
  line-height: 1.56;
}

%style-copy-small {
  font-size: 1.6rem;
  line-height: 1.56;
}

%style-copy-large {
  font-size: 2rem;
  line-height: 1.33;

  @include bp(md) {
    font-size: 2.4rem;
    line-height: 1.25;
  }
}

%style-tag {
  font-size: 1.4rem;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}

%style-label {
  @extend %style-copy;

  padding: 0.1em 0.5em 0;
  background-color: rgba($color-lt-blue, 0.2);
  line-height: 1.25;
}

%style-link {
  @extend %link;

  display: inline-block;
  padding: 0.1em 0.25em;
  background-color: rgba($color-lt-blue, 0.2);
  font-size: 1.8rem;
  line-height: 1;
}

%style-knob {
  font-family: $font-martin;
  font-weight: bold;
  line-height: 0.85;
  letter-spacing: 0.12em;
  text-transform: uppercase;
}

%style-stat {
  font-family: $font-league;
  font-size: 6.5rem;
  font-weight: bold;
  line-height: 0.9;
  text-transform: uppercase;
}
