/* ==========================================================================
   #COLUMNS
   ========================================================================== */

.c-columns__lead {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: $layer-base + 1;

  @include bp(md) {
    background-color: var(--section-bg-color);

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 100%;
      left: -50vw;
      width: calc(50vw + 100%);
      background-color: var(--section-bg-color);
    }

    > * {
      position: relative;
      z-index: $layer-base;
    }
  }

  .c-lead {
    // max-width: 30rem;

    @include bp(sm) {
      max-width: 48rem;
    }

    @include bp(md) {
      max-width: 28rem;
    }
  }

  .c-lead__title {
    color: var(--section-title-color, $color-red);
    margin-top: -0.125em;
  }

  .c-lead__copy {
    @include bp(md) {
      max-width: 26rem;
    }
  }

  // Horizontal alignment variants
  &--align-center {
    .c-lead {
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      text-align: center;

      @include bp(md) {
        align-items: flex-start;
        margin-left: initial;
        margin-right: initial;
        text-align: left;
      }
    }
  }

  // Vertical alignment variants
  &--valign-top {
    justify-content: flex-start;
  }

  &--valign-middle {
    justify-content: center;
  }

  &--valign-bottom {
    justify-content: flex-end;
  }
}

.c-columns__content {
  // Vertical alignment variants
  &--valign-top {
    align-self: flex-start;
  }

  &--valign-middle {
    align-self: center;
  }

  &--valign-bottom {
    align-self: flex-end;
  }
}
