/* ==========================================================================
   #ACCORDION
   ========================================================================== */

.c-accordion {
  position: relative;

  &.is-expanded {
    padding-bottom: var(--spacer-padding-bottom);
  }
}

.c-accordion__head {
  &--expandable {
    padding-bottom: var(--spacer-padding-bottom);

    .is-expanded & {
      padding-bottom: 0;
    }

    @include bp(md) {
      padding-bottom: 0;
    }
  }
}

.c-accordion__copy {
  padding-right: 12.5%;

  @include bp-down(xl) {
    padding-right: 15%;
    font-size: 2rem;
    line-height: 1.3;
  }
}

.c-accordion__button {
  --expander-bg-color: #{$color-navy};
  --expander-color: var(--section-bg-color);
  --expander-active-bg-color: #{$color-purple};

  position: absolute;
  top: 100%;
  right: 0;
  margin-top: -0.5em;
}

.c-accordion__wrapper {
  display: none;

  .is-expanded & {
    display: block;
  }
}

.c-accordion__block {
  margin: calc(var(--spacer-padding-bottom) * 0.75)
    calc(var(--container-gutter) * -1) 0;
  padding: calc(var(--spacer-padding-top) * 0.75) var(--container-gutter) 0;
  border-top: 1px solid rgba($color-navy, 0.15);

  &:first-child {
    margin-top: var(--spacer-padding-bottom);
  }
}

.c-accordion__media {
  width: 100%;
  max-width: 25rem;
}

.c-accordion__content {
  img {
    @include bp-down(md) {
      &:not(:first-child) {
        margin-top: 2em;
      }

      + * {
        margin-top: 1em !important;
      }
    }
  }

  &--with-image {
    img {
      &:not(:first-child) {
        margin-top: 2em;
      }

      + * {
        margin-top: 1em !important;
      }
    }
  }

  &--without-image {
    @include bp(md) {
      img {
        margin: 0;
        float: left;
        margin-left: -27.5%;
        transform: translateX(-50%);
      }

      img:not(:first-child) {
        margin-top: 2.5em;
      }

      img:first-child + * {
        margin-top: 0 !important;
      }
    }

    @include bp(xl) {
      img {
        margin-left: -20%;
      }
    }
  }
}
