/* ==========================================================================
   #NORMALIZE
   ========================================================================== */

* {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: 0;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  text-align: inherit;
  text-transform: inherit;
}

*,
::before,
::after {
  box-sizing: inherit;
}

a,
button,
[type='button'] {
  cursor: pointer;
  text-decoration: none;

  &:focus {
    outline: none;
  }
}

img,
iframe {
  display: block;
  max-width: 100%;
}

ul,
ol {
  list-style: none;
}

sub,
sup {
  position: relative;
  display: inline-block;
  margin-left: 0.1em;
  font-size: 0.6em;
  line-height: 1;
  vertical-align: 0.3em;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.5em;
}
