/* ==========================================================================
   #LINKS
   ========================================================================== */

a,
button {
  @extend %link-transitions;

  cursor: pointer;
}
