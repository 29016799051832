// ==========================================================================
// #SPACERS
// ==========================================================================

// A helper for the spacing mixing
@mixin _spacer($size, $direction, $property, $scale, $spacer) {
  $spacer-breakpoints: (
    xs: 3,
    md: 2,
    lg: 1,
    xl: 0,
  );

  @if $scale >= 1 {
    $value: calc(#{$spacer} * #{$size});

    #{$property}-#{$direction}: $value;

    --spacer-#{$property}-#{$direction}: #{$value};
  } @else {
    @each $breakpoint, $breakpoint-scale in $spacer-breakpoints {
      @include bp($breakpoint) {
        $value: calc(
          #{$spacer} * #{$size * (1 - ($scale * $breakpoint-scale))}
        );

        #{$property}-#{$direction}: $value;

        --spacer-#{$property}-#{$direction}: #{$value};
      }
    }
  }
}

// Adds flexible spacer to the element
@mixin spacer(
  $size: 1,
  $direction: top,
  $property: margin,
  $scale: 0.25,
  $spacer: $global-spacer
) {
  @if ($property == margin and $size > 0) {
    @if index(top left, $direction) {
      &:not(:first-child) {
        @include _spacer($size, $direction, $property, $scale, $spacer);
      }
    } @else {
      &:not(:last-child) {
        @include _spacer($size, $direction, $property, $scale, $spacer);
      }
    }
  } @else {
    @include _spacer($size, $direction, $property, $scale, $spacer);
  }
}

// Adds font-size relative spacer to the element
@mixin spacer-relative(
  $size: 1,
  $direction: 'top',
  $property: 'margin',
  $scale: 1
) {
  @include spacer(
    $size,
    $direction,
    $property,
    $scale,
    $font-line-height * 1em
  );
}

// Adds fixed spacer to the element
@mixin spacer-fixed($size: 1, $direction: 'top', $property: 'margin') {
  @include spacer($size, $direction, $property, 1);
}
