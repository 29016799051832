/* ==========================================================================
   #WRAPPER
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

.o-wrapper {
  position: relative;
  z-index: $layer-base;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition-property: opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;

  .has-menu-open & {
    opacity: 0.5;
    transition-delay: 0.41s;
  }
}
