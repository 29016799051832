/* ==========================================================================
   #POST
   ========================================================================== */

.c-post {
  @extend %style-copy-large;
}

.c-post__head {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.c-post__title {
  @include spacer-fixed(0.75);

  @extend %link-block;
}

.c-post__excerpt {
  @include spacer-fixed(0.75);

  max-width: 56rem;
}

.c-post__labels {
  @include spacer-fixed(1.25);
}

.c-post__date {
  @include spacer(2.5);

  @extend %style-copy;

  color: $color-tan;
}

.c-post__copy,
.c-post__content {
  @include spacer(2.5);
}
