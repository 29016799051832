/* ==========================================================================
   #DIAGRAM
   ========================================================================== */

.c-diagram {
  position: relative;
  width: 70vw;
  margin: 3em auto;
  font-size: 3.2rem;
  line-height: 0.75;

  @include bp(md) {
    margin-top: 1.75em;
    margin-bottom: 1.75em;
    font-size: 4.2rem;
  }

  @include bp(lg) {
    max-width: 72rem;
    font-size: 5.2rem;
  }
}

.c-diagram__image {
  width: 100%;
}

.c-diagram__value {
  @extend %heading;

  position: absolute;
  z-index: $layer-base;
  letter-spacing: 0.12em;
  text-align: center;
  white-space: nowrap;

  &:nth-child(1) {
    bottom: 100%;
  }

  &:nth-child(2) {
    left: 100%;
    transform-origin: top left;
    transform: rotate(90deg) translate(-50%, -100%);
  }

  &:nth-child(3) {
    top: 100%;
  }

  &:nth-child(4) {
    right: 100%;
    transform-origin: top right;
    transform: rotate(-90deg) translate(50%, -100%);
  }

  &:nth-child(1),
  &:nth-child(3) {
    margin-bottom: 0.75em;
    margin-top: 0.75em;
    left: 50%;
    transform: translateX(-50%);

    @include bp(lg) {
      margin-bottom: 1em;
      margin-top: 1em;
    }
  }

  &:nth-child(2),
  &:nth-child(4) {
    margin-left: 5vw;
    margin-right: 5vw;
    top: 50%;

    @include bp(lg) {
      margin-left: 1em;
      margin-right: 1em;
    }

    @include bp(xl) {
      margin-left: 1.75em;
      margin-right: 1.75em;
    }
  }
}
