/* ==========================================================================
   #HEADING
   ========================================================================== */

%heading {
  font-weight: bold;
  font-family: $font-martin;
  font-stretch: condensed;
  text-transform: uppercase;
}

%heading-h1 {
  font-size: 9.4rem;
  line-height: 0.73;

  @include bp(md) {
    font-size: 11.6rem;
    line-height: 0.68;
  }

  @include bp(lg) {
    font-size: 13.4rem;
    line-height: 0.63;
  }
}

%heading-h2 {
  font-size: 7.4rem;
  line-height: 0.73;

  @include bp(md) {
    font-size: 9rem;
    line-height: 0.73;
  }

  @include bp(lg) {
    font-size: 10rem;
    line-height: 0.7;
  }
}

%heading-h3 {
  font-size: 4rem;
  line-height: 0.9;

  @include bp(md) {
    font-size: 5rem;
    line-height: 0.85;
  }

  @include bp(xl) {
    font-size: 6rem;
    line-height: 0.8;
  }
}

%heading-h4 {
  font-size: 4rem;
  line-height: 0.9;

  @include bp(md) {
    font-size: 5.2rem;
  }
}

%heading-h5 {
  font-size: 3.6rem;
  line-height: 1;

  @include bp(md) {
    font-size: 4.2rem;
  }
}

%heading-h6 {
  font-size: 3rem;
  line-height: 1;

  @include bp(md) {
    font-size: 3.6rem;
  }
}
