/* ==========================================================================
   #TAG
   ========================================================================== */

.c-tag {
  @extend %style-tag;
  @extend %ellipsis;

  display: inline-flex;
  align-items: center;
  padding: 0.55em 0.8em 0.45em;
  background-color: var(--tag-bg-color, #{rgba($color-lt-blue, 0.2)});
  border-radius: $global-radius;
  color: var(--tag-color, $color-navy);

  @include bp(md) {
    padding: 0.65em 1em 0.55em;
  }

  &[href]:hover {
    opacity: 0.8;
  }
}

.c-tag__group {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: inherit;
  gap: 1rem;

  @include bp(md) {
    row-gap: 1.5rem;
  }
}

/* Color variants
   ========================================================================== */

@each $default, $hover in $color-tags {
  $i: index($color-tags, $default $hover);

  .c-tag--color-#{$i} {
    --tag-bg-color: #{$default};
    --tag-color: #{$color-brown};

    &[href]:hover {
      --tag-bg-color: #{$hover};
    }
  }
}

.c-tag__close {
  --plus-width: 1em;
  --plus-height: 0.125em;

  @extend %plus-sign;

  padding: 0.5em;
  margin: -0.5em;
  margin-left: 0;
  transform: translate(0.25em, -0.1em) rotate(45deg);

  &:hover {
    color: $color-black;
  }
}

/* Other variants
   ========================================================================== */

.c-tag--small {
  font-size: 1.2rem;
}

.c-tag--tight {
  padding: 0.45em 0.75em 0.35em;
}

.c-tag--selected,
.c-tag.is-selected {
  opacity: 0.5;
}

.c-tag.is-selected {
  transition: none;
}
