/* ==========================================================================
   #FILTERS
   ========================================================================== */

.c-filters__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: calc(var(--column-gutter) * 1);

  @include bp(md) {
    align-items: center;
    gap: calc(var(--column-gutter) * 2);
  }

  @include bp(xl) {
    flex-direction: row;
    justify-content: space-evenly;
  }
}

.c-filters__title {
  white-space: nowrap;

  @include bp(xl) {
    width: 25%;
  }
}

.c-filters {
  position: relative;
  z-index: $layer-dropdown;
  width: 100%;
}

.c-filters__item {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  transition: opacity $global-transition-speed;

  @include bp(md) {
    position: static;
  }
}

.c-filters__button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.625em 0;
  transition: opacity $global-transition-speed;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    width: 100%;
    background-color: currentColor;
    opacity: 0.125;
    transition: opacity $global-transition-speed;
  }

  .c-icon {
    opacity: 0.25;
    transition: opacity $global-transition-speed;
  }

  &:hover {
    &::after,
    .c-icon {
      opacity: 0.5;
    }
  }

  .is-open &,
  &--selected {
    &::after,
    .c-icon {
      opacity: 1;
    }
  }

  .has-dropdown-open & {
    opacity: 0.4;
  }

  .has-dropdown-open .is-open & {
    opacity: 1;
  }
}

.c-filters__label {
  @extend %ellipsis;

  margin-right: 0.75em;
}

.c-filters__close {
  --plus-width: 1.25em;
  --plus-height: 0.125em;

  @extend %plus-sign;

  margin-right: 0.75em;
  transform: rotate(45deg);

  &:hover {
    opacity: 0.5;
  }
}

.c-filters__trigger {
  margin-left: auto;
  font-size: 1.5em;
}

.c-filters__clear {
  @extend %style-tag;

  position: absolute;
  top: 100%;
  left: 50%;
  margin-top: 2rem;
  padding: 0.65em 1em 0.55em;
  border: 1px solid currentColor;
  border-radius: $global-radius;
  white-space: nowrap;
  transform: translateX(-50%);

  @include bp(xl) {
    left: 0;
    transform: none;
  }

  &:hover {
    background-color: rgba($color-white, 0.15);
  }
}

.c-filters__dropdown {
  @include dropdown($color-snow, 1rem);

  padding: 1.5em;

  .is-open & {
    @include dropdown-open;
  }
}
