/* ==========================================================================
   #PAGINATION
   ========================================================================== */

.c-pagination {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: $global-spacer;
  width: 100%;
}

.c-pagination__item {
  @extend %link;

  display: flex;
  align-items: center;
  gap: 1em;
}

.c-pagination__link {
  font-weight: bold;

  @include bp(md) {
    font-size: 1.25em;
  }
}

.c-pagination__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5em;
  height: 2.5em;
  background-color: $color-shade;
  border-radius: 50%;
  text-align: center;

  @include bp(md) {
    font-size: 1.8rem;
  }

  .c-icon--chevron-left {
    transform: translateX(-0.1em);
  }

  .c-icon--chevron-right {
    transform: translateX(0.1em);
  }
}
