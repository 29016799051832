/* ==========================================================================
   #BLOCK
   ========================================================================== */

.c-block {
  --block-padding: #{$global-spacer * 1.5} #{$global-spacer * 4} #{$global-spacer *
    1.5} #{$global-spacer * 1.5};

  position: relative;
  min-height: 30rem;
  padding: var(--block-padding);
  background-color: var(--block-bg-color, #{$color-shade});
  cursor: pointer;
  user-select: none;

  @include bp(sm) {
    --block-padding: #{$global-spacer * 2} #{$global-spacer * 5} #{$global-spacer *
      2} #{$global-spacer * 2};
  }

  @include bp(md) {
    --block-padding: #{$global-spacer * 2.5} #{$global-spacer * 6} #{$global-spacer *
      2.5} #{$global-spacer * 3};
  }
}

.c-block__list {
  display: grid;
  gap: $global-spacer * 2;
  grid-template-columns: repeat(1, 1fr);
  width: 100%;

  @include bp(xl) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.c-block__title {
  max-width: 17em;
  line-height: 1.11;

  @include bp(xl) {
    max-width: 11em;
  }
}

.c-block__button {
  --expander-bg-color: var(--block-active-bg-color);
  --expander-color: var(--block-bg-color);
  --expander-active-bg-color: var(--block-bg-color);
  --expander-active-color: var(--block-active-bg-color);

  position: absolute;
  bottom: 0.5em;
  right: 0.5em;
  z-index: $layer-base;

  @include bp(md) {
    bottom: 1em;
    right: 1em;
  }
}

.c-block__overlay {
  position: absolute;
  inset: 0;
  padding: var(--block-padding);
  background-color: var(--block-active-bg-color, #{$color-black});
  color: $color-white;
  overflow-y: auto;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
  opacity: 0;
  pointer-events: none;
  transition: opacity $global-transition-speed;

  .is-expanded &,
  .c-block--preview:focus & {
    opacity: 1;
    pointer-events: all;
  }
}

.c-block__content {
  @include bp(lg) {
    max-width: 92.5%;
  }

  a[href]:not([class]):hover {
    color: currentColor;
  }
}
