/* ==========================================================================
   #NOTICE
   ========================================================================== */

.u-notice {
  @extend %style-copy-large;

  text-align: center;
  opacity: 0.5;
}
