/* ==========================================================================
   #MAP
   ========================================================================== */

.c-map {
  display: flex;
  flex-direction: column;
  align-items: center;

  .o-section--color-green & {
    color: $color-tusk;
  }
}

.c-map__filters {
  width: 100%;
}

.c-map__image {
  user-select: none;
  pointer-events: none;
}

.c-map__content {
  position: relative;
  width: 100%;
  max-width: 101.5rem;

  &--small {
    max-width: 72rem;
  }

  &:not(:first-child) {
    margin-top: var(--spacer-padding-bottom);
  }
}

.c-map__stories {
  position: absolute;
  inset: 0;
}

.c-map__trigger {
  position: relative;
  display: block;
  width: 5.25%;
  color: $color-yellow;
  user-select: none;

  &:hover {
    opacity: 0.8;
  }

  &[style] {
    position: absolute;
    z-index: $layer-base;
    transform: translate(-50%, -50%);
  }
}

.c-map__plus {
  --plus-width: 1.25em;
  --plus-height: 0.25em;

  position: absolute;
  inset: 0;
  display: flex;
  align-items: flex-end;

  span {
    @extend %plus-sign;

    margin-left: -0.75em;
    margin-bottom: -0.25em;
  }
}
