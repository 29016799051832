/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  padding-top: 3rem;
  padding-bottom: 3rem;
  border-top: 1px solid $color-shade;
}

.c-footer__copy {
  font-size: 0.875em;
  line-height: 1.5;
  text-align: center;

  @include bp(md) {
    font-size: 1em;
  }

  &:not(:first-child) {
    margin-top: 1.5em;

    @include bp(md) {
      margin-top: 0.5em;
    }
  }

  a {
    @extend %link;
  }
}
