// ==========================================================================
// #DIMMER
// ==========================================================================

@mixin dimmer($classes, $layer, $color, $opacity: 0.75) {
  &::after {
    @include u-overlay(0, 'fixed');

    content: '';
    z-index: $layer;
    background-color: rgba($color, $opacity);
    opacity: 0;
    pointer-events: none;
    transform: translateX(-300%);
    transition-property: opacity, transform;
    transition-duration: $global-transition-speed * 2, 0s;
    transition-delay: 0s $global-transition-speed * 2;
  }

  @if type_of($classes) == list {
    @each $class in $classes {
      &.#{$class}::after {
        opacity: 1;
        transform: translateX(0);
        pointer-events: all;
        transition-delay: 0s;
      }
    }
  } @else {
    &.#{$classes}::after {
      opacity: 1;
      transform: translateX(0);
      pointer-events: all;
      transition-delay: 0s;
    }
  }
}
