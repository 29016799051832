/* ==========================================================================
   #SLIDER
   ========================================================================== */

.c-slider {
  padding-bottom: var(--spacer-padding-bottom);

  &::after {
    content: 'flickity';
    display: none;
  }

  @include bp(lg) {
    padding-bottom: 0;

    .flickity-page-dots {
      margin-top: calc(var(--spacer-padding-bottom) * 0.5);
    }
  }
}

.c-slider__title {
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
  padding-bottom: 0.25em;
  background-color: $color-white;
  text-align: center;
  transform: translateY(calc(var(--spacer-padding-bottom) * -1));
}

.c-slider__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--column-gutter);
  width: 100%;

  @include bp(md) {
    flex-direction: row;
    // align-items: flex-start;
  }

  @include bp(lg) {
    gap: calc(var(--column-gutter) * 2);
  }

  @include bp(xl) {
    padding-left: 4.5vw;
    padding-right: 4.5vw;
  }
}

.c-slider__image {
  width: 100%;
  max-width: 25rem;

  @include bp(md) {
    max-width: 28rem;
  }

  @include bp(lg) {
    width: 43.6rem;
    max-width: 40%;
  }
}

.c-slider__content {
  align-self: center;
  flex: 1;

  @include bp(md) {
    align-items: flex-start;
    margin-left: 0;
    text-align: left;
  }
}
