/* ==========================================================================
   #MEDIA
   ========================================================================== */

.c-media {
  position: relative;
  display: block;
  overflow: hidden;
  background-color: $color-titan;
  mask-image: radial-gradient(white, black);

  @include bp(lg) {
    max-height: 42vw;
  }

  &::before {
    content: '';
    display: block;
  }

  img {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform $global-transition-speed;
  }

  [href]:hover > & img,
  button:hover > & img,
  &[href]:hover img {
    transform: scale(1.05);
  }
}

/* Aspect variants
    ========================================================================== */

.c-media--3-2::before {
  padding-bottom: 150%;
}

.c-media--1-1::before {
  padding-bottom: 100%;
}

.c-media--1-2::before {
  padding-bottom: 50%;
}

/* Border radius variants
    ========================================================================== */

.c-media--shape-rounded {
  border-radius: $global-radius;
}

.c-media--shape-circle {
  border-radius: 50%;
}

.c-media--shape-pill {
  border-radius: 100vmin;
}
