/* ==========================================================================
   #TILE
   ========================================================================== */

.c-tile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  max-width: 27rem;
  margin-left: auto;
  margin-right: auto;
}

.c-tile__partner {
  &--has-logo {
    height: 3.2rem;

    @include bp(md) {
      height: 2.5rem;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center left;
    }
  }
}

.c-tile__title {
  &:not(:first-child) {
    margin-top: $global-spacer;
  }

  &:not(:last-child) {
    margin-bottom: auto;
  }

  a {
    display: inline-block;

    &:hover {
      color: $color-link;
    }
  }
}

.c-tile__tags {
  &:not(:first-child) {
    margin-top: $global-spacer;
    padding-top: $global-spacer;
    border-top: 1px solid rgba($color-lt-blue, 0.5);
  }
}
