/* ==========================================================================
   #POPUP
   ========================================================================== */

.c-popup {
  @include u-overlay(0, 'fixed');

  z-index: $layer-popup;
  overflow: hidden;
  pointer-events: none;
  transform: translateX(-300%);
  transition: transform 0s $global-transition-speed;

  .has-popup-open & {
    transform: translateX(0);
    pointer-events: all;
    transition: none;
  }
}

.c-popup__close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: $layer-base;
  padding: 2rem;
  color: $color-purple;
  font-size: 2.4rem;
  text-transform: uppercase;

  @include bp(lg) {
    padding: 3rem;
  }

  &:hover {
    opacity: 0.75;
  }

  .has-iframe & {
    color: $color-white;
  }
}

.c-popup__content {
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(100% - (var(--container-gutter)));
  max-height: calc(100% - (var(--container-gutter)));
  max-width: 90rem;
  background-color: $color-white;
  box-shadow: 0 0.5rem 4rem rgba($color-black, 0.15);
  border-radius: $global-radius;
  opacity: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  margin-top: 5%;
  transform: translate3d(-50%, -50%, 0);
  transition: opacity $global-transition-speed * 1.5,
    margin $global-transition-speed * 1.5;

  @include bp(lg) {
    width: calc(100% - (var(--container-gutter) * 4));
  }

  .has-popup-open & {
    opacity: 1;
    margin-top: 0;
  }

  &.has-iframe {
    max-width: none;
  }
}

.c-popup__inner {
  padding: var(--container-gutter);

  @include bp(md) {
    padding-left: calc(var(--container-gutter) * 2);
    padding-right: calc(var(--container-gutter) * 2);

    @media screen and (min-height: 900px) {
      padding-top: calc(var(--container-gutter) * 2);
      padding-bottom: calc(var(--container-gutter) * 2);
    }
  }

  img,
  video,
  iframe {
    display: block;
    width: 100%;
  }

  .has-iframe & {
    padding: 0;
  }
}
