/* ==========================================================================
   #EXPANDER
   ========================================================================== */

.c-expander {
  @extend %link-transitions;
  @extend %plus-sign;

  width: 1em;
  height: 1em;
  background-color: var(--expander-bg-color, transparent);
  box-shadow: 0 0.5rem 1.5rem 2px rgba($color-black, 0.15);
  color: var(--expander-color, currentColor);
  border-radius: 50%;
  font-size: 5rem;

  &:hover,
  [data-expand]:hover & {
    opacity: 0.8;
  }

  .is-expanded & {
    background-color: var(--expander-active-bg-color, var(--expander-bg-color));
    color: var(--expander-active-color, var(--expander-color));
  }
}
