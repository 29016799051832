/* ==========================================================================
   #FORM
   ========================================================================== */

.c-form {
  max-width: 32rem;
  margin-left: auto;
  margin-right: auto;
}

.c-form__field {
  &:not(:first-child) {
    margin-top: 4rem;
  }

  &--separated:not(:first-child) {
    @include bp(lg) {
      margin-top: 6rem;
    }
  }
}

.c-form__input[name] {
  @extend %input;

  width: 100%;
  padding: 0.25em 0;
  border-bottom: 2px solid $color-lt-blue;
  font-size: 1.8rem;
  text-align: left;
  transition: border-color $global-transition-speed;

  &::placeholder {
    color: $color-purple;
  }

  &:focus,
  &:not(:placeholder-shown) {
    border-bottom-color: $color-navy;
  }
}

.c-form__button {
  width: 100%;
}
