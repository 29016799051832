/* ==========================================================================
   #NAV TERTIARY
   ========================================================================== */

.c-nav-tertiary {
  display: inline-flex;
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.c-nav-tertiary__item {
  padding-left: 0.5em;
  padding-right: 0.5em;
  border-left: 1px solid $color-purple;
  border-right: 1px solid $color-purple;

  &:not(:first-child) {
    margin-left: -1px;
  }
}
