// ==========================================================================
// #ANIMATIONS
// ==========================================================================

@keyframes menu-animation {
  0% {
    opacity: 0;
    transform: scale(0.04) translateY(300%);
  }

  40% {
    transform: scale(0.04) translateY(0);
    transition: ease-out;
  }

  40% {
    transform: scale(0.04) translateY(0);
  }

  60% {
    opacity: 1;
    transform: scale(0.02) translateY(0);
  }

  61% {
    transform: scale(0.04);
  }

  99.9% {
    height: 0;
    padding-bottom: 100%;
    border-radius: 100%;
  }

  100% {
    transform: scale(2);
    height: 100%;
    padding-bottom: 0;
    border-radius: 0;
  }
}
