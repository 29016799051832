/* ==========================================================================
   #CARD
   ========================================================================== */

.c-card__image {
  display: block;
  position: relative;
}

.c-card__overlay {
  position: absolute;
  left: 1em;
  right: 1em;
  bottom: 1.75em;
  z-index: $layer-base;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.c-card__tag {
  display: flex;
  min-width: 0;
  margin-right: 1em;
}

.c-card__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 2.25em;
  width: 2.25em;
  margin-top: -0.5em;
  margin-bottom: -0.5em;
  margin-left: auto;
  background-color: $color-tan;
  border-radius: 50%;
  color: $color-white;
  font-size: 2.2rem;

  &--large {
    @include bp(lg) {
      font-size: 2.6rem;
    }
  }
}

.c-card__title {
  @extend %link-block;
}

.c-card__content {
  @include spacer-fixed(1.25);
}

.c-card__title,
.c-card__programs {
  @include spacer-fixed(0.75);
}
