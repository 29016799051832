/* ==========================================================================
   #FORMS
   ========================================================================== */

// Input initial styles
%input {
  display: block;
  width: 100%;
  margin: 0;
  border: 0;
  border-radius: 0;
  font-size: inherit;
  font-weight: inherit;
  line-height: normal;
  appearance: none;

  &::placeholder {
    color: inherit;
    opacity: 1;
  }

  &:focus {
    outline: none;
  }

  &[rows] {
    resize: none;
  }

  &[type] {
    @extend %ellipsis;
  }

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    appearance: none;
  }

  &:-webkit-autofill {
    animation-delay: 1s;
    animation-name: autofill;
    animation-fill-mode: both;
  }
}
